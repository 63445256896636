import { InputField } from "../common/InputField";
// import CartBasinBubble from "./CartBasinBubble";
import CartBasinDate from "./CartBasinDate";
import CartBasinMultiSelectFields from "./CartBasinMultiSelectFields";
import UploadFileModal from "../common/Modal/UploadFileModal";
import {
    cartBasinDates as dateList,
    // drillTypeList,
    productType,
    inputFieldProps,
    multiSelectProps,
    menuOption,
    drillTypeOption,
    wellTypeOption,
    wellStatusOption,
} from "./CartBasinConstant";
import {
    showUploadModal,
    hideUploadModal,
    showSiteLoader,
    hideSiteLoader,
    toggleUploadingCsvApiListModal,
    toggleApiUpgradeSubModal,
    hideCheckOutModal,
} from "../store/actions/modal-actions";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CartBasinSchema } from "../../Helper/validation";
import Moment from "moment";
import { splitValues } from "../../Helper/common";
import {
    DO_NOT_SHOW_UPGRADE_MODAL,
    actionType,
    aoiPathname,
    extractOption,
    handleCsvFile,
    handleShapeFile,
    removeNullOrEmptyValue,
} from "../../utils/helper";
import ExportToCsvModal from "./ExportToCsvModal";
import {
    CartBasinFilterState,
    OptionType,
    reactSelectProps,
    searchListObject,
} from "../models/page-props";
import CartBasinModal from "./CartBasinModal";
import {
    clearProductionData,
    clearRigsData,
    clearWellsData,
    getWellsAndPermitList,
    // handleDownloadCol,
    handlePageChange,
    handleShowAndHideSegmentDropDown,
    showHideColProperties,
    showHideFullScreen,
    toggleViewAnalytics,
    handleSelectedWellRigOrPermitList,
    handleTabIndex,
    handleSortAndOrderBy,
} from "../store/actions/wells-rigs-action";
import { toast } from "react-toastify";
import moment from "moment";
import UploadingCsvApiListModal from "./UploadingCsvApiListModal";
import {
    fetchOptionsList,
    fetchSavedSearchFiltersByID,
    // handleAdvancedFilter,
    handleClearAllFilter,
    handleSavedFilter,
    handleSliderValue,
    // fetchSliderMaxValue,
    // handleSliderValue,
    handleWellApiListAfterCsvUpload,
    saveSearchFilters,
    uploadShapeFileOrCsv,
} from "../store/actions/cart-basin-to-county-actions";
import { csvApiDataObj } from "../models/submit-form";
import ApiUpgradeSubModal from "./ApiUpgradeSubModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentModal from "../common/Modal/PaymentModal";
import {
    getApiListAfterCompletingThePayment,
    getCartDetails,
    removeCartItems,
} from "../store/actions/cart-select-basin-county-actions";
import DeleteConfirmationModal from "../common/Modal/DeleteConfirmationModal";
import Scrollbars from "react-custom-scrollbars";
import SliderMinMax from "./SliderMinMax";
import { downloadFileLogs } from "../store/actions/files-actions";
import { logUserAction } from "../store/actions/auth-actions";
import { tableColObje } from "../models/redux-models";
import AdvancedFilterCom from "./AdvancedFilterCom";
import { File } from "../models/redux-models";
import AoiSideCon from "../aoi/AoiSideCon";
import { useLocation } from "react-router-dom";
import SaveSearchFiltersComp from "./SaveSearchFiltersComp";
import {
    setCartBasinFilterState,
    setCsvApiFileLoading,
    setCsvApiFileData,
    setNotInPlan,
    setByBasinInTabData,
    setByCountyInTabData,
    setCsvApiFileName,
    setCsvApiFileSize,
    setCsvApiFile,
    setFileToOpen,
    setOpenModalAFterUploadModal,
    setGeometry,
    setEpsg,
    setFormData,
    setDeleteItemId,
    setDeleteCartItemModal,
    setDeleteCartItemType,
    setApiFileWellApiList,
    setShapeFileModal
} from "../store/actions/cart-basin-to-filter-actions"
import { UpdateColorByField } from "../map/redux/filters";
import RapidAPIFilter from "./RapidAPIFilter";

const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const CartBasinFilterSection = () => {
    const itemsRef = useRef<HTMLDivElement[]>([]);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        watch,
        clearErrors,
        reset,
        register,
    } = useForm({
        resolver: yupResolver(CartBasinSchema),
    });
    const location = useLocation()
    const hookObject: { [x: string]: OptionType } = { statekey: [] };
    hookObject["statekey"] = watch("state_abbr");
    hookObject["countyKey"] = watch("county");
    hookObject["api_file"] = watch("api_file");
    hookObject["well_api"] = watch("well_api");
    // hookObject["category"] = watch("category");
    const { statekey, api_file, well_api, countyKey
        // category 
    } = hookObject;

    const [queryId, setQueryId] = useState({
        uid: "",
        rig_id: ""
    });
    const { uid, rig_id } = queryId

    const onChangeStartDate = (date: Date, name: string) => {
        if (date) {
            dateList?.forEach(({ unique_name, fields }) => {
                if (unique_name === name) {
                    clearErrors(fields[0].f_fieldname);
                    setValue(fields[0].f_fieldname, date);
                    setValue(
                        fields[0].l_fieldname,
                        Moment(
                            Moment(getValues()[fields[0].l_fieldname]).format(
                                "MMM-DD-YYYY"
                            )
                        ).isAfter(Moment(date).format("MMM-DD-YYYY"))
                            ? getValues()[fields[0].l_fieldname]
                            : null
                    );
                }
            });
        }
    };

    const onChangeEndDate = (date: Date, name: string) => {
        if (date) {
            dateList?.forEach(({ unique_name, fields }) => {
                if (unique_name === name) {
                    setValue(fields[0].l_fieldname, date);
                }
            });
        }
    };

    // Send extra param to fetchOptionHandler Attribute
    const multiStepOptionExtraField = [
        // Follow Order As Per Field
        { extrafield: null },
        { extrafield: null },
        { extrafield: null },
        { extrafield: null },
        { extrafield: null },
        { extrafield: null },
        {
            extrafield: {
                state: splitValues(statekey),
            },
        },
        {
            extrafield: {
                state: splitValues(statekey),
                county: splitValues(countyKey),
            },
        },
        {
            extrafield: null,
        },
        {
            extrafield: null,
        },
        {
            extrafield: null,
        },
        {
            extrafield: null,
        },
        {
            extrafield: null,
        },
        {
            extrafield: null,
        },
        {
            extrafield: null,
        },
    ];
    // keys to create multiselect as dependent dropdown
    const multiSelectUniqCache = [
        // Follow Order As Per Field
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [splitValues(statekey)] },
        { uniqCache: [splitValues(statekey), splitValues(countyKey)] },
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [] },
        { uniqCache: [] },
        // { uniqCache: [splitValues(category)] },
        { uniqCache: [] },

    ];

    const dispatch = useAppDispatch();
    const {
        modal: {
            uploadModal,
            uploadingCsvApiListModal,
            apiUpgradeSubModal,
            checkOutModal,
        },
        auth: {
            user: { access_token },
        },
        wellsAndRigs: {
            wellsData: { wellsDataLoading,
            },
            rigsData: { rigsDataLoading,
            },
            permitsData: { permitsDataLoading,
            },
            productionData: { productionDataLoading,
            },
            tabIndex,
            wellsPage,
            rigsPage,
            permitsPage,
            productionPage,
            filterSearch,
            sort_order,
            sort_by,
            colProperties,
            viewAnalytics,
            fullScreen,
            filter,
            filter_param,
            segment_id,
            showTableLoader,
            downloadCol,
            tableCol,
            rigsTableCol,
            allCol,
            showSegmentDropDown,
            productionCol,
            fullScrnAnalytics,
            excel_format,
            selectedAoiData: { aoi_id },
            selectedWellRigOrPermitUIDList,
            rapidAPIOnBlur,
            download_without_api_call,
            rapidAPIFromInput
        },
        filters: { heatmapGridTotals },
        cartSelectBasinCounty: { cartListItems },
        cartBasinToCounty: { clearAllFilter, wellApiListAfterCsvUpload, sliderMinMaxValue, leftTab, showAdvancedFilter, savedFilter: { openSavedFilter } },
        cartBasinToFilterSlice: { shapeFileModal,
            fileToOpen,
            isChooseColumn,
            isExportOther,
            formData,
            geometry,
            epsg,
            csvApiFileName,
            csvApiFileSize,
            csvApiFileLoading,
            deleteCartItemModal,
            deleteItemId,
            deleteItemType,
            sub_total,
            notInPlan,
            byBasinTabData,
            byCountyTabData,
            apiFileWellApiList, }
    } = useAppSelector((state) => state);

    const col = tabIndex === 0 || tabIndex === 2 ? tableCol : tabIndex === 1 ? rigsTableCol : productionCol;

    const initialStateObj = {
        shapeFileModal: false,
        fileToOpen: 0,
        openModalAFterUploadModal: false,
        isChooseColumn: false,
        isExportOther: false,
        formData: {
            well_type: wellTypeOption.filter((_item) => _item.value === "production").map((item) => item.value),

            production_type: productType.filter((item) => (item.value === "oil" || item.value === "gas" || item.value === "oil/gas" || item.value === "unknown")).map((item) => item.value),

            well_status: wellStatusOption.filter((_item) => (_item.value === "pre-permit" || _item.value === "active permit" || _item.value === "drilled-uncompleted" || _item.value === "active" || _item.value === "Inactive")).map((item) => item.value),

            drill_type: [...drillTypeOption.map((item) => item.value), "Select All"],

        },
        file: null,
        geometry: "",
        epsg: "",
        csvApiFileName: "",
        csvApiFileSize: 0,
        csvApiFileData: [],
        csvApiUnmatchedFileData: [],
        csvApiMatchedFileData: [],
        csvApiFileLoading: true,
        deleteCartItemModal: false,
        deleteItemId: null,
        deleteItemType: null,
        sub_total: 0,
        notInPlan: false,
        byBasinTabData: [],
        byCountyTabData: [],
        apiFileWellApiList: [],
    }

    const handleFileChange = async (acceptedFile: File | File[]) => {
        dispatch(showSiteLoader());
        try {
            if (Array.isArray(acceptedFile)) return;
            let fileType = acceptedFile.name.split(".").pop();
            if (fileType && ["csv"].includes(fileType)) {
                const csvResponse = await handleCsvFile(acceptedFile);
                if (csvResponse?.status && csvResponse.status === 200) {
                    const {
                        meta: { fields },
                    } = csvResponse.data;
                    let temp_fields = fields.map((item: string) => item.trim());
                    if (
                        (
                            temp_fields.includes("api") || temp_fields.includes("API") ||
                            temp_fields.includes("Well API") ||
                            temp_fields.includes("Well_api") ||
                            temp_fields.includes("well_api") ||
                            temp_fields.includes("api_well")
                        ) ||
                        ((
                            temp_fields.includes("well_name") || temp_fields.includes("WELL_NAME") || temp_fields.includes("name")
                            || temp_fields.includes("well")
                            || temp_fields.includes("well name")
                            || temp_fields.includes("name_well")
                            || temp_fields.includes("Well name")
                            || temp_fields.includes("WELL NAME")
                        )
                            &&
                            temp_fields.includes("state") &&
                            temp_fields.includes("county"))
                    ) {
                        dispatch(hideUploadModal());
                        dispatch(setCsvApiFileLoading(true))
                        dispatch(
                            uploadShapeFileOrCsv(access_token, {
                                file: acceptedFile,
                            }, "upload_api_list")
                        ).then((res) => {
                            const { data, status, msg } = res || {};
                            if (status === 200 && data) {
                                const {
                                    filter_data,
                                    not_in_plan,
                                    by_basin,
                                    by_county,
                                } = data;
                                if (Array.isArray(filter_data)) {
                                    dispatch(setCsvApiFileData(filter_data.map(
                                        (item, index) => ({
                                            ...item,
                                            wellMatching:
                                                item.status ===
                                                    "no match" ||
                                                    item.status ===
                                                    "not in plan"
                                                    ? null
                                                    : [
                                                        {
                                                            label: `API: ${item.api} - ${item.well_name}`,
                                                            value: item.api,
                                                        },
                                                    ],
                                            id: index + 1,
                                        })
                                    ),))
                                }
                                if (notInPlan) {
                                    dispatch(setNotInPlan(notInPlan))
                                }
                                if (by_basin &&
                                    by_basin.length > 0) {
                                    dispatch(setByBasinInTabData(by_basin.filter(
                                        (item) =>
                                            item.basin_name !== "na"
                                    ),))
                                }
                                if (by_county &&
                                    by_county.length > 0) {
                                    dispatch(setByCountyInTabData(by_county.filter(
                                        (item) => item.county !== "na"
                                    ),))
                                }

                                dispatch(setCsvApiFileName(acceptedFile.name))
                                dispatch(setCsvApiFileSize(acceptedFile.size / Math.pow(10, 6)))
                                dispatch(setCsvApiFileLoading(false))
                                handleOnClick()
                            } else {
                                toast.error(msg);
                                dispatch(setCsvApiFileLoading(true))
                                dispatch(showUploadModal());
                                dispatch(hideSiteLoader())
                            }
                        });
                    } else {
                        toast.error(
                            "Please check your file. Your file may not contain the api or well_name, state, and county column. Please refer sample csv file for reference."
                        );
                        dispatch(hideSiteLoader())
                    }
                }
                // dispatch(hideSiteLoader());
            } else {
                handleShapeFile(acceptedFile)
                    .then((res) => {
                        if (res?.status && res.status === 200) {
                            dispatch(hideUploadModal());
                            dispatch(setCsvApiFile(acceptedFile))
                            dispatch(setFileToOpen(2))
                            dispatch(setOpenModalAFterUploadModal(true))
                            dispatch(hideSiteLoader());
                        }
                    })
                    .catch((error) => {
                        dispatch(hideSiteLoader());
                    });
            }
        } catch (error) { }
    };

    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                event.preventDefault();
                if (!sessionStorage.getItem("menuIsOpen")) {
                    /* If we have a value for the rapid API then the enter button 
                    should work for that in RapidAPIFilter.tsx and not the Filters.
                    We were having an issue with the enter key for the rapid
                    API also firing the onSubmit handler of this form.  Therefore we are 
                    setting the message of loading during the rapid api call and then clearing
                    it on a finally or after an error toast to prevent this.  And it also is
                    used to store the custom error message to toast on error response from the 
                    backend */
                    !fullScrnAnalytics && !rapidAPIOnBlur && onSubmit(getValues());
                } else {
                    sessionStorage.removeItem("menuIsOpen");
                }
            }
        };
        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        }
        // eslint-disable-next-line 
    }, [tabIndex, fullScrnAnalytics, rapidAPIOnBlur])

    const setDefaultValue = () => {
        let tempCategory = menuOption.filter((item, index) => index === 0 || index === 2)
        let tempSubCategory: reactSelectProps[] = [];
        tempCategory.forEach((item, index) => {
            tempSubCategory = [...tempSubCategory, ...(index === 0 ? [item.subMenu[0]] : item.subMenu)]
        })

        //set the initial value of formData
        dispatch(setFormData({ ...formData, ...initialStateObj.formData }))

        const { production_type, well_type, well_status } = initialStateObj.formData;
        //for selecting default value for the below drop down
        setValue('drill_type', drillTypeOption)

        setValue('production_type', productType.filter((item) => JSON.stringify(production_type).includes(item.value)))


        well_type.length && setValue('well_type', wellTypeOption.filter((item) => JSON.stringify(well_type).includes(item.value)));

        well_status.length && setValue('well_status', wellStatusOption.filter((item) => JSON.stringify(well_status).includes(item.value)));

        // For slider
        setValue("minMeasuredDepth", 0);
        setValue("minTrueVerDepth", 0);
        setValue("minLateralDepth", 0);
        setValue(
            "maxLateralDepth",
            sliderMinMaxValue.lateral_length
        );
        setValue(
            "maxTrueVerDepth",
            sliderMinMaxValue.true_vertical_depth
        );
        setValue(
            "maxMeasuredDepth",
            sliderMinMaxValue.measured_depth
        );

        dispatch(handleSliderValue({
            minLateralLength: 0,
            minTrueVerticalDepth: 0,
            minMeasuredDepth: 0,
            maxLateralLength: sliderMinMaxValue['lateral_length'],
            maxTrueVerticalDepth: sliderMinMaxValue['true_vertical_depth'],
            maxMeasuredDepth: sliderMinMaxValue['measured_depth']

        }))
    }

    useLayoutEffect(() => {
        if (location?.state?.uid || location?.state?.rig_id) {
            setQueryId((prev) => ({
                ...prev,
                ...(location.state.uid && {
                    uid: location.state.uid
                }),
                ...(location.state.rig_id && {
                    uid: location.state.rig_id
                })
            }))
            window.history.replaceState({}, document.title)
        }

    }, [JSON.stringify(location?.state)])

    useEffect(() => {
        setDefaultValue()
        // if (uid || rig_id) {
        //     dispatch(setRapidAPIFromInput((uid || rig_id).trim()));
        //     window.history.replaceState({}, document.title)
        // }
        // Setting the tabIndex when we have rigId in the query.
        if (rig_id && tabIndex !== 1) {
            dispatch(handleTabIndex(1));
        }
        /* NOTE:- After the code refactoring made by @Jacob while implementing the fuzzy logic, an issue occurred where the default value was not passed in the payload during initial loading. I attempted to use useLayoutEffect and useEffect to update the formState key in the Redux store, but it did not work due to delays in the update.
       To resolve this, I changed the logic as follows:
        Set the default loading flag of the wells data to false.
        Manually called the onSubmit function after setting the default value to the form, which then makes the loading flag true again.
        The reason for this approach is that the onSubmit function will fetch the latest value from the form, thereby overcoming the issue with default value updates.*/
        onSubmit({ ...getValues() })
        return () => {
            checkOutModal && dispatch(hideCheckOutModal());
            apiUpgradeSubModal && dispatch(toggleApiUpgradeSubModal());
            viewAnalytics && dispatch(toggleViewAnalytics());
            fullScreen && dispatch(showHideFullScreen());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // if (statekey !== "") getCounty();
        if (!restrictApiCalling.current) {
            if (statekey && statekey.length > 0) {
                !JSON.stringify(statekey).toLowerCase().includes("texas") &&
                    setValue("abstract", "");
                !JSON.stringify(statekey).toLowerCase().includes("texas") &&
                    setValue("block", "");
                !JSON.stringify(statekey).toLowerCase().includes("texas") &&
                    setValue("legaldesc_survey", []);
                (!JSON.stringify(statekey)
                    .toLowerCase()
                    .includes("west virginia") ||
                    !JSON.stringify(statekey)
                        .toLowerCase()
                        .includes("pennsylvania") ||
                    !JSON.stringify(statekey).toLowerCase().includes("Ohio")) &&
                    setValue("parcel", "");
            } else {
                setValue("abstract", "");
                setValue("block", "");
                setValue("legaldesc_survey", []);
                setValue("parcel", "")
            }
            setValue("county", []);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statekey]);

    useEffect(() => {
        if (wellApiListAfterCsvUpload.length > 0) {
            setValue("well_api", [
                ...(well_api || []),
                ...wellApiListAfterCsvUpload,
            ]);
            dispatch(handleWellApiListAfterCsvUpload([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wellApiListAfterCsvUpload]);

    const restrictApiCalling = useRef(false);

    useEffect(() => {
        if (restrictApiCalling.current) {
            return;
        };
        const {
            // measured_depth,
            // true_vertical_depth,
            // lateral_length,
            minMeasuredDepth,
            minTrueVerticalDepth,
            minLateralLength,
            maxMeasuredDepth,
            maxTrueVerticalDepth,
            maxLateralLength, } = sliderMinMaxValue;
        let formObj =
        // uid.length ? {
        //     uid: uid.filter((_id) => (!JSON.stringify(data).includes(_id) && `${_id}`))
        // } :
        {
            page: tabIndex === 0 ? wellsPage : tabIndex === 1 ? rigsPage : tabIndex === 2 ? permitsPage : productionPage,
            ...(aoi_id && location.pathname === aoiPathname && !downloadCol && { aoi_id }),
            ...(tabIndex !== 0 && { search_type: tabIndex === 1 ? "rigs" : tabIndex === 2 ? "permit" : "production" }),
            ...(filterSearch && {
                search_param: filterSearch,
            }),
            ...(sort_order && { sort_order }),
            ...(sort_by && { sort_by }),
            ...(formData !== null && {
                ...formData,
                ...(minMeasuredDepth === 0 && maxMeasuredDepth === 0 ? {} : {
                    depth: {
                        min: minMeasuredDepth,
                        max: maxMeasuredDepth,
                    }
                }),
                ...(minTrueVerticalDepth === 0 && maxTrueVerticalDepth === 0 ? {} : {
                    vertical_depth: {
                        min: minTrueVerticalDepth,
                        max: maxTrueVerticalDepth,
                    }
                }),
                ...(minLateralLength === 0 && maxLateralLength === 0 ? {} : {
                    lateral_length: {
                        min: minLateralLength,
                        max: maxLateralLength,
                    }
                }),
            }),
            ...(geometry && { geometry }),
            ...(epsg && { epsg }),
            ...(filter && { filter }),
            ...(filter_param.length && { filter_param }),
            ...(segment_id && { segment_id }),
            ...(downloadCol && { download: downloadCol }),
            ...(downloadCol && excel_format && { format: "excel" }),
            ...(downloadCol && {
                download_column: allCol ?
                    [...col.map((_item) => "dbKeyName" in _item ? _item.dbKeyName : _item.label),
                    ]
                    :
                    [...(col).filter((item: tableColObje) => item.status).map((_item: tableColObje) => "dbKeyName" in _item ? _item.dbKeyName : _item.label),
                    ]
            }),
            // When we get `uid` or `rig_id` in the query, we pass these values into the `uid` key and `rig_id_query_string` key as booleans. If `rig_id` is present, the key is set to `true`; if `uid` is present, it is set to `false`. If the `rig_id_query_string` is present, we call the `handleSelectedWellRigOrPermitList` action after the API call for `getWells` to ensure that our well or rig ID gets highlighted.
            ...(((selectedWellRigOrPermitUIDList.length && !downloadCol) || (uid || rig_id)) && ({
                uid: [...(selectedWellRigOrPermitUIDList || []), ...(selectedWellRigOrPermitUIDList.includes(`${uid || rig_id}`) ? [] : [`${uid || rig_id}`])]
            })),
            ...((uid || rig_id) && {
                rig_id_query_string: rig_id ? true : false
            })
        }
        // Logging the download of table data when it is greater than the download limit.
        if (downloadCol && !download_without_api_call) {
            dispatch(downloadFileLogs(access_token, { download_loc: "2", search_param: JSON.stringify(formObj) }));
        }
        // Logging the download of table data when it is less than the download limit and returning from this 'if' statement, so we do not call the 'getWellsAPI' action.
        if (download_without_api_call) {
            dispatch(downloadFileLogs(access_token, { download_loc: "2", search_param: JSON.stringify(formObj) }));
            dispatch(
                logUserAction({
                    action_type: actionType["download_docs"],
                    action_log_detail: JSON.stringify(formObj),
                })
            );
            return
        }

        if ((wellsDataLoading || downloadCol) && tabIndex === 0) {
            dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                    },
                    (wellsPage === 1 || downloadCol) ? true : false
                )
            );

            !downloadCol && !selectedWellRigOrPermitUIDList.length && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        tabIndex: 1,
                        search_type: "rigs",
                    }
                )
            );

            !downloadCol && !selectedWellRigOrPermitUIDList.length && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        search_type: 'well_count_by_county'
                    },
                    false
                )
            );
            !downloadCol && !selectedWellRigOrPermitUIDList.length && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        search_type: 'well_count_by_grid_id',
                    },
                    false
                )
            );
            setQueryId(prev => ({ ...prev, uid: "", rig_id: '' }))            
            return;
        }
        if ((permitsDataLoading || downloadCol) && tabIndex === 2) {
            dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                    },
                    (permitsPage === 1 || downloadCol) ? true : false
                )
            );

            !downloadCol && !selectedWellRigOrPermitUIDList.length && (!(uid || rig_id)) && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        search_type: 'well_count_by_county'
                    },
                    false
                )
            );
            !downloadCol && !selectedWellRigOrPermitUIDList.length && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        search_type: 'well_count_by_grid_id',
                    },
                    false
                )
            );

            return;
        }
        if ((rigsDataLoading || downloadCol) && tabIndex === 1) {
            dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                    },
                    (rigsPage === 1 || downloadCol) ? true : false
                )
            );
            !downloadCol && !selectedWellRigOrPermitUIDList.length && (!(uid || rig_id)) && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        search_type: 'well_count_by_county'
                    },
                    false
                )
            );
            !downloadCol && !selectedWellRigOrPermitUIDList.length && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        search_type: 'well_count_by_grid_id',
                    },
                    false
                )
            );
            setQueryId(prev => ({ ...prev, uid: "", rig_id: '' }));
            return;
        }
        if ((productionDataLoading || downloadCol) && tabIndex === 3) {
            dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                    },
                    (productionPage === 1 || downloadCol) ? true : false
                )
            );
            !downloadCol && !selectedWellRigOrPermitUIDList.length && (!(uid || rig_id)) && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        search_type: 'well_count_by_county'
                    },
                    false
                )
            );
            !downloadCol && !selectedWellRigOrPermitUIDList.length && !("aoi_id" in formObj) && dispatch(
                getWellsAndPermitList(
                    access_token,
                    {
                        ...formObj,
                        search_type: 'well_count_by_grid_id',
                    },
                    false
                )
            );
            return;
        }
        // eslint-disable-next-line
    }, [wellsDataLoading, permitsDataLoading, tabIndex, downloadCol, productionDataLoading, aoi_id, restrictApiCalling.current]);


    const onSubmit = (data: FieldValues) => {
        //save the filters
        if ('saveFilters' in data && data['saveFilters']) {
            'saveFilters' in data && delete data['saveFilters'];
            let tempData = {
                ...data,
                ...(tabIndex !== 0 && { search_type: tabIndex === 1 ? "rigs" : tabIndex === 2 ? "permit" : "production" }),
                ...(sort_order && { sort_order }),
                ...(sort_by && { sort_by }),
                ...(sliderMinMaxValue['minMeasuredDepth'] === 0 && sliderMinMaxValue['maxMeasuredDepth'] === 0 ? {} : {
                    depth: [
                        sliderMinMaxValue['minMeasuredDepth'],
                        sliderMinMaxValue['maxMeasuredDepth'],
                    ]
                }),
                ...(sliderMinMaxValue['minTrueVerticalDepth'] === 0 && sliderMinMaxValue['maxTrueVerticalDepth'] === 0 ? {} : {
                    vertical_depth: [
                        sliderMinMaxValue['minTrueVerticalDepth'],
                        sliderMinMaxValue['maxTrueVerticalDepth'],
                    ]
                }),
                ...(sliderMinMaxValue['minLateralLength'] === 0 && sliderMinMaxValue['maxLateralLength'] === 0 ? {} : {
                    lateral_length: [
                        sliderMinMaxValue['minLateralLength'],
                        sliderMinMaxValue['maxLateralLength'],
                    ]
                }),
                ...(geometry && { geometry }),
                ...(epsg && { epsg }),
                tabIndex
            }
            removeNullOrEmptyValue(tempData)
            dispatch(saveSearchFilters(tempData))
            return
        }


        let formData = {
            ...(data.drill_type?.length > 0 && {
                drill_type: [...data.drill_type.map((item: obj) =>
                    item.value
                ), ...(data.drill_type.length === drillTypeOption.length ? ["Select All"] : [])],
            }),
            ...(data.production_type?.length > 0 && {
                production_type: data.production_type.map((item: obj) =>
                    item.value
                ),
            }),
            ...(data.name?.length > 0 && {
                well_name: data.name.map((item: obj) => item.value),
            }),
            ...(data.well_status?.length > 0 && {
                well_status: data.well_status.map(
                    (item: obj) => item.value
                ),
            }),
            ...(data.well_type?.length > 0 && {
                well_type: data.well_type.map((item: obj) => item.value),
            }),
            ...(data.well_api?.length > 0 && {
                well_no: data.well_api.map((item: obj) => item.value),
            }),
            ...(data.operator_name?.length > 0 && {
                operator: data.operator_name.map((item: obj) => item.value),
            }),
            ...(data.state_abbr?.length > 0 && {
                state_abbr: data.state_abbr.map((item: obj) => item.value),
            }),
            ...(data.county?.length > 0 && {
                county: data.county.map((item: obj) => item.value),
            }),
            ...(data.basin_name?.length > 0 && {
                basin: data.basin_name.map((item: obj) => item.value),
            }),
            ...(data.reservoir?.length > 0 && {
                reservoir: data.reservoir.map((item: obj) => item.value),
            }),
            ...(data.legaldesc_township?.length > 0 && {
                township: data.legaldesc_township.map((item: obj) => item.value),
            }),
            ...(data.legaldesc_range?.length > 0 && {
                range: data.legaldesc_range.map((item: obj) => item.value),
            }),
            ...(data.legaldesc_section?.length > 0 && {
                section: data.legaldesc_section.map((item: obj) => item.value),
            }),
            ...(data.legaldesc_abstract?.length > 0 && {
                abstract: data.legaldesc_abstract.map((item: obj) => item.value),
            }),
            ...(data.legaldesc_block?.length > 0 && {
                block: data.legaldesc_block.map((item: obj) => item.value),
            }),
            ...(data?.legaldesc_survey?.length > 0 && {
                survey: data.legaldesc_survey.map(
                    (item: obj) => item.value
                ),
            }),
            ...(data.depth &&
                (data.depth[0] !== 0 || data.depth[1] !== 0) && {
                depth: {
                    min: data.depth[0],
                    max: data.depth[1],
                },
            }),
            ...(data.vertical_depth &&
                (data.vertical_depth[0] !== 0 ||
                    data.vertical_depth[1] !== 0) && {
                vertical_depth: {
                    min: data.vertical_depth[0],
                    max: data.vertical_depth[1],
                },
            }),
            ...(data?.lateral_length &&
                (data?.lateral_length[0] !== 0 ||
                    data.lateral_length[1] !== 0) && {
                lateral_length: {
                    min: data.lateral_length[0],
                    max: data.lateral_length[1],
                },
            }),
            ...((data.prodEndDate || data.prodStartDate) && {
                production_date: {
                    ...(data.prodStartDate && {
                        start: moment(data.prodStartDate)
                            .format("MMM-DD-YYYY")
                            .split("/")
                            .join("-"),
                    }),
                    ...(data.prodEndDate && {
                        end: moment(data.prodEndDate)
                            .format("MMM-DD-YYYY")
                            .split("/")
                            .join("-"),
                    }),
                },
            }),
            ...((data.permStartDate || data.permEndDate) && {
                permit_date: {
                    ...(data.permStartDate && {
                        start: moment(data.permStartDate)
                            .format("MMM-DD-YYYY")
                            .split("/")
                            .join("-"),
                    }),
                    ...(data.permEndDate && {
                        end: moment(data.permEndDate)
                            .format("MMM-DD-YYYY")
                            .split("/")
                            .join("-"),
                    }),
                },
            }),
            ...((data.spudEndDate || data.spudStartDate) && {
                spud_date: {
                    ...(data.spudStartDate && {
                        start: moment(data.spudStartDate)
                            .format("MMM-DD-YYYY")
                            .split("/")
                            .join("-"),
                    }),
                    ...(data.spudEndDate && {
                        end: moment(data.spudEndDate)
                            .format("MMM-DD-YYYY")
                            .split("/")
                            .join("-"),
                    }),
                },
            }),
            ...((data.compStartDate || data.compEndDate) && {
                completion_date: {
                    ...(data.compStartDate && {
                        start: moment(data.compStartDate)
                            .format("MMM-DD-YYYY")
                            .split("/")
                            .join("-"),
                    }),
                    ...(data.compEndDate && {
                        end: moment(data.compEndDate)
                            .format("MMM-DD-YYYY")
                            .split("/")
                            .join("-"),
                    }),
                },
            }),
        }


        //log user execute filters
        dispatch(
            logUserAction({
                action_type: actionType['execute_filter'],
                action_log_detail: JSON.stringify({
                    ...formData, ...('geometry' in data && restrictApiCalling.current && { geometry: data['geometry'] }),
                    ...('epsg' in data && restrictApiCalling.current && { epsg: data['epsg'] }),
                })
            })
        );


        // clear the selected well if there is any.  After running a filter
        // we wanted to clear any selected well on the map
        dispatch(handleSelectedWellRigOrPermitList([]))

        type obj = { label: string; value: string };
        dispatch(setFormData(formData))
        dispatch(handlePageChange(1));
        dispatch(clearWellsData());
        dispatch(clearRigsData());
        dispatch(clearProductionData());
        dispatch(UpdateColorByField("well_status"));
    };

    const handleOnClick = () => {
        if (notInPlan && !sessionStorage.getItem(DO_NOT_SHOW_UPGRADE_MODAL)) {
            dispatch(getCartDetails(access_token));
            dispatch(toggleApiUpgradeSubModal());
            return;
        }
        dispatch(setFileToOpen(1))
        dispatch(setOpenModalAFterUploadModal(true))
        // Note :- instead of closing site loader here closing it when api list match modal open (CartBasinOpenModalAfterAPiModal in this component)
    };

    const handleConWithoutUpgrade = () => {
        dispatch(toggleApiUpgradeSubModal());
        dispatch(setFileToOpen(1))
        dispatch(setOpenModalAFterUploadModal(true))
    };

    const removeBasinOrCounty = (id: number, item_type: number) => {
        dispatch(setDeleteItemId(id))
        dispatch(setDeleteCartItemModal(true))
        dispatch(setDeleteCartItemType(item_type))
    };

    const updateFilterAndUnmatchedDataAfterSub = () => {
        dispatch(
            getApiListAfterCompletingThePayment(access_token, csvApiFileName)
        ).then((res) => {
            const { status, msg, data } = res;
            if (status === 200 && data) {
                const { filter_data } = data;
                dispatch(setCsvApiFileData(filter_data.map(
                    (item, index) => ({
                        ...item,
                        wellMatching:
                            item.status ===
                                "no match" ||
                                item.status ===
                                "not in plan"
                                ? null
                                : [
                                    {
                                        label: `API: ${item.api} - ${item.well_name}`,
                                        value: item.api,
                                    },
                                ],
                        id: index + 1,
                    })
                )))
                dispatch(setFileToOpen(1))
                dispatch(setOpenModalAFterUploadModal(true))
            } else {
                toast.error(msg);
            }
        });
    };

    useEffect(() => {
        if (Array.isArray(api_file)) {
            let tempWellApi = well_api.filter(
                (item) =>
                    !JSON.stringify(apiFileWellApiList).includes(
                        JSON.stringify(item.value)
                    )
            );
            if (api_file.length) {
                dispatch(
                    fetchOptionsList(
                        {
                            search_field: "csv_file_id",
                            id: Number(api_file[0].value),
                        },
                        true
                    )
                ).then((res) => {
                    const { status, data } = res;
                    if (status === 200) {
                        let tempData = extractOption(data, "matched_api_id");
                        setValue("well_api", [...tempWellApi, ...tempData]);
                        dispatch(setApiFileWellApiList(tempData.map(
                            (item) => item.value as string
                        )))

                    }
                });
            } else {
                dispatch(setApiFileWellApiList([]))
                setValue("well_api", [...tempWellApi]);
            }
        }
        // eslint-disable-next-line
    }, [api_file]);

    useEffect(() => {
        if (clearAllFilter) {
            dispatch(setFormData(null))
            reset()

            dispatch(handleClearAllFilter(false))
        }
        // eslint-disable-next-line
    }, [clearAllFilter])

    const fetchFilterById = (id: number) => {
        restrictApiCalling.current = true;
        dispatch(fetchSavedSearchFiltersByID(id)).then(res => {
            const { status, data } = res;
            if (status === 200) {
                reset()
                if ("maxLateralDepth" in data || "maxMeasuredDepth" in data || "maxTrueVerDepth" in data || "minLateralDepth" in data || "minMeasuredDepth" in data || "minTrueVerDepth" in data) {
                    dispatch(handleSliderValue({
                        ...("maxLateralDepth" in data && {
                            maxLateralLength: data['maxLateralDepth'],
                        }),
                        ...("maxMeasuredDepth" in data && {
                            maxMeasuredDepth: data['maxMeasuredDepth'],
                        }),
                        ...("maxTrueVerDepth" in data && {
                            maxTrueVerticalDepth: data['maxTrueVerDepth'],
                        }),
                        ...("minLateralDepth" in data && {
                            minLateralLength: data['minLateralDepth'],
                        }),
                        ...("minMeasuredDepth" in data && {
                            minMeasuredDepth: data['minMeasuredDepth'],
                        }),
                        ...("minTrueVerDepth" in data && {
                            minTrueVerticalDepth: data['minTrueVerDepth'],
                        }),
                    }))
                }
                Object.entries(data).forEach((item) => {
                    switch (item[0]) {
                        case "permStartDate":
                            setValue("permStartDate", moment(
                                item[1]
                            ).toDate())

                            break;
                        case "permEndDate": setValue("permEndDate", moment(
                            item[1]
                        ).toDate())

                            break;
                        case "spudStartDate": setValue("spudStartDate", moment(
                            item[1]
                        ).toDate())

                            break;
                        case "spudEndDate": setValue("spudEndDate", moment(
                            item[1]
                        ).toDate())

                            break;
                        case "compStartDate": setValue("compStartDate", moment(
                            item[1]
                        ).toDate())

                            break;
                        case "compEndDate": setValue("compEndDate", moment(
                            item[1]
                        ).toDate())

                            break;
                        case "prodStartDate": setValue("prodStartDate", moment(
                            item[1]
                        ).toDate())

                            break;
                        case "prodEndDate": setValue("prodEndDate", moment(
                            item[1]
                        ).toDate())

                            break;
                        default:
                            setValue(item[0], item[1])

                    }

                })
                'tabIndex' in data && dispatch(handleTabIndex(data['tabIndex']));
                'sort_order' in data && dispatch(handleSortAndOrderBy({
                    sort_order: data['sort_order'],
                    ...('sort_by' in data && { sort_by: data['sort_by'] }),
                }));
                // 'county' in data && setValue('county',data['county'])
                onSubmit({ ...data });
            }
        }).finally(() => {
            restrictApiCalling.current = false;
        })
    }


    return (
        <>
            {
                location.pathname === aoiPathname ? <AoiSideCon /> : <div
                    className={`filterCon ${!leftTab ? "isHide" : ''}  ${showAdvancedFilter ? "d-none" : ''}`}
                    onClick={() => {
                        //this is close the menu when use click on filter section
                        dispatch(showHideColProperties(false));
                        // csvDownOpt && dispatch(showHideCsvDownOpt());
                        showSegmentDropDown && dispatch(handleShowAndHideSegmentDropDown(false))
                        //to close the save Filter if it is open
                        openSavedFilter && dispatch(handleSavedFilter({ openSavedFilter: !openSavedFilter }))
                    }}
                >
                    <div className="filter-header">
                        <span>
                            {" "}
                            Filters
                        </span>{" "}
                        {/* <button className="btn-filter" onClick={() => {
                    let myElement = document.getElementById("filterToggleMove");
                    if (myElement) myElement.classList.add("advanceFiltermap")
                    dispatch(handleAdvancedFilter(true))
                }}><img src="images/advance-filter.svg" alt="Filter" /> Advanced</button> */}


                        {/* <a href="void:(0)" onClick={(e) => {
                    e.preventDefault()
                    dispatch(toggleLeftTab(false));
                }}>
                    <i className="fa-solid fa-xmark"></i>
                </a> */}
                        <div className="dropdown">
                            <span className="filter-btn" onClick={(e) => {
                                e.stopPropagation();
                                dispatch(handleSavedFilter({ openSavedFilter: !openSavedFilter, filterID: 0 }))
                            }}><img src="images/filter-icon.svg" alt="" /></span>
                            <SaveSearchFiltersComp
                                saveSearchFiltersFn={
                                    () => {
                                        dispatch(
                                            handleSavedFilter({
                                                saveFiltersDataLoading: true,
                                            })
                                        );
                                        onSubmit({ ...getValues(), saveFilters: true })
                                    }
                                }
                                fetchFilterById={fetchFilterById}
                            />
                        </div>
                    </div>
                    <div className="filter-form">
                        <form onSubmit={handleSubmit(onSubmit)} id="cartBasinFilterForm">
                            <Scrollbars
                                className="filtersidebar-scroll"
                                autoHeightMin={0}
                                renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                )}
                                renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                )}
                            >
                                <div className="filtersidebar">
                                    <div className="form-group">
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index === 22 && (
                                                        <div key={index}>
                                                            <CartBasinMultiSelectFields
                                                                index={index}
                                                                async={true}
                                                                isMulti={true}
                                                                name={val.name}
                                                                control={
                                                                    control
                                                                }
                                                                defaultValue={[]}
                                                                itemsRef={
                                                                    itemsRef
                                                                }
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                onChangeHandle={(val, actionMeta) => {
                                                                    if (Array.isArray(val) && val.length > 0) {
                                                                        if (actionMeta.action === "select-option" && actionMeta.option?.value === "select_all") {
                                                                            setValue("well_type", wellTypeOption);
                                                                            return
                                                                        }
                                                                        if (actionMeta.action === "deselect-option" && actionMeta.option?.value !== "select_all") {
                                                                            setValue("well_type", val.filter((item) => item.value !== "select_all"));
                                                                            return
                                                                        }
                                                                        if (actionMeta.action === "deselect-option" && actionMeta.option?.value === "select_all") {
                                                                            setValue("well_type", []);
                                                                            return
                                                                        }

                                                                        if (val.length === wellTypeOption.length - 1) {
                                                                            setValue("well_type", [...val, {
                                                                                label: 'Select All',
                                                                                value: 'select_all'
                                                                            }]);
                                                                            return
                                                                        }


                                                                    } else {
                                                                        setValue('well_type', [])
                                                                    }
                                                                }
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]
                                                                        ?.extrafield
                                                                }
                                                            />


                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index === 11 && (
                                                        <div key={index}>
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <div className="upload-wrapper">
                                                                <div>
                                                                    <CartBasinMultiSelectFields
                                                                        index={index}
                                                                        async={true}
                                                                        isMulti={true}
                                                                        name={val.name}
                                                                        control={
                                                                            control
                                                                        }
                                                                        defaultValue={[]}
                                                                        itemsRef={
                                                                            itemsRef
                                                                        }
                                                                        showerror={
                                                                            val.showerror
                                                                        }
                                                                        placeholderText={
                                                                            val.placeholderText
                                                                        }
                                                                        fetchOptionHandler={
                                                                            val.fetchOptionHandler
                                                                        }
                                                                        onChangeHandle={(val, actionMeta) => {
                                                                            if (Array.isArray(val) && val.length > 0) {
                                                                                if (actionMeta.action === "select-option" && actionMeta.option?.value === "select_all") {
                                                                                    setValue("well_type", wellTypeOption);
                                                                                    return
                                                                                }
                                                                                if (actionMeta.action === "deselect-option" && actionMeta.option?.value !== "select_all") {
                                                                                    setValue("well_type", val.filter((item) => item.value !== "select_all"));
                                                                                    return
                                                                                }
                                                                                if (actionMeta.action === "deselect-option" && actionMeta.option?.value === "select_all") {
                                                                                    setValue("well_type", []);
                                                                                    return
                                                                                }

                                                                                if (val.length === wellTypeOption.length - 1) {
                                                                                    setValue("well_type", [...val, {
                                                                                        label: 'Select All',
                                                                                        value: 'select_all'
                                                                                    }]);
                                                                                    return
                                                                                }


                                                                            } else {
                                                                                setValue('well_type', [])
                                                                            }
                                                                        }
                                                                        }
                                                                        searchPlaceholderText={
                                                                            val.searchPlaceholderText
                                                                        }
                                                                        cacheUniqs={
                                                                            multiSelectUniqCache[
                                                                                index
                                                                            ]?.uniqCache
                                                                        }
                                                                        extraField={
                                                                            multiStepOptionExtraField[
                                                                                index
                                                                            ]
                                                                                ?.extrafield
                                                                        }
                                                                    ></CartBasinMultiSelectFields>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index === 2 && (
                                                        <div
                                                            className={`col-md-${val.gridSize}`}
                                                            key={index}
                                                        >
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <CartBasinMultiSelectFields
                                                                index={index}
                                                                isMulti={true}
                                                                async={true}
                                                                name={val.name}
                                                                control={control}
                                                                defaultValue={[]}
                                                                itemsRef={itemsRef}
                                                                onChangeHandle={(val, actionMeta) => {
                                                                    if (Array.isArray(val) && val.length > 0) {
                                                                        if (actionMeta.action === "select-option" && actionMeta.option?.value === "select_all") {
                                                                            setValue("well_status", wellStatusOption);
                                                                            return
                                                                        }
                                                                        if (actionMeta.action === "deselect-option" && actionMeta.option?.value !== "select_all") {
                                                                            setValue("well_status", val.filter((item) => item.value !== "select_all"));
                                                                            return
                                                                        }
                                                                        if (actionMeta.action === "deselect-option" && actionMeta.option?.value === "select_all") {
                                                                            setValue("well_status", []);
                                                                            return
                                                                        }

                                                                        if (val.length === wellStatusOption.length - 1) {
                                                                            setValue("well_status", [...val, {
                                                                                label: 'Select All',
                                                                                value: 'select_all'
                                                                            }]);
                                                                            return
                                                                        }


                                                                    } else {
                                                                        setValue('well_status', [])
                                                                    }
                                                                }
                                                                }
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]?.extrafield
                                                                }
                                                            />
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index === 15 && (
                                                        <div key={index}>
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <div className="upload-wrapper">
                                                                <div>
                                                                    <CartBasinMultiSelectFields
                                                                        index={index}
                                                                        isMulti={true}
                                                                        async={true}
                                                                        name={val.name}
                                                                        control={
                                                                            control
                                                                        }
                                                                        defaultValue={[]}
                                                                        onChangeHandle={(val, actionMeta) => {

                                                                            if (Array.isArray(val) && val.length > 0) {
                                                                                if (actionMeta.action === "select-option" && actionMeta.option?.value === "Select All") {
                                                                                    setValue("drill_type", drillTypeOption);
                                                                                    return
                                                                                }
                                                                                if (actionMeta.action === "deselect-option" && actionMeta.option?.value !== "Select All") {
                                                                                    setValue("drill_type", val.filter((item) => item.value !== "Select All"));
                                                                                    return
                                                                                }
                                                                                if (actionMeta.action === "deselect-option" && actionMeta.option?.value === "Select All") {
                                                                                    setValue("drill_type", []);
                                                                                    return
                                                                                }

                                                                                if (val.length === drillTypeOption.length - 1) {
                                                                                    setValue("drill_type", [...val, {
                                                                                        label: 'Select All',
                                                                                        value: 'Select All'
                                                                                    }]);
                                                                                    return
                                                                                }


                                                                            } else {
                                                                                setValue('drill_type', [])
                                                                            }
                                                                        }
                                                                        }
                                                                        itemsRef={
                                                                            itemsRef
                                                                        }
                                                                        showerror={
                                                                            val.showerror
                                                                        }
                                                                        placeholderText={
                                                                            val.placeholderText
                                                                        }
                                                                        fetchOptionHandler={
                                                                            val.fetchOptionHandler
                                                                        }
                                                                        searchPlaceholderText={
                                                                            val.searchPlaceholderText
                                                                        }
                                                                        cacheUniqs={
                                                                            multiSelectUniqCache[
                                                                                index
                                                                            ]?.uniqCache
                                                                        }
                                                                        extraField={
                                                                            multiStepOptionExtraField[
                                                                                index
                                                                            ]
                                                                                ?.extrafield
                                                                        }
                                                                    ></CartBasinMultiSelectFields>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index === 16 && (
                                                        <div key={index}>
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <div className="upload-wrapper">
                                                                <div>
                                                                    <CartBasinMultiSelectFields
                                                                        index={index}
                                                                        async={true}
                                                                        isMulti={true}
                                                                        name={val.name}
                                                                        control={
                                                                            control
                                                                        }
                                                                        defaultValue={[]}
                                                                        onChangeHandle={(val, actionMeta) => {

                                                                            if (Array.isArray(val) && val.length > 0) {
                                                                                if (actionMeta.action === "select-option" && actionMeta.option?.value === "select_all") {
                                                                                    setValue("production_type", productType);
                                                                                    return
                                                                                }
                                                                                if (actionMeta.action === "deselect-option" && actionMeta.option?.value !== "select_all") {
                                                                                    setValue("production_type", val.filter((item) => item.value !== "select_all"));
                                                                                    return
                                                                                }
                                                                                if (actionMeta.action === "deselect-option" && actionMeta.option?.value === "select_all") {
                                                                                    setValue("production_type", []);
                                                                                    return
                                                                                }

                                                                                if (val.length === productType.length - 1) {
                                                                                    setValue("production_type", [...val, {
                                                                                        label: 'Select All',
                                                                                        value: 'select_all'
                                                                                    }]);
                                                                                    return
                                                                                }


                                                                            } else {
                                                                                setValue('production_type', [])
                                                                            }
                                                                        }
                                                                        }
                                                                        itemsRef={
                                                                            itemsRef
                                                                        }
                                                                        showerror={
                                                                            val.showerror
                                                                        }
                                                                        placeholderText={
                                                                            val.placeholderText
                                                                        }
                                                                        fetchOptionHandler={
                                                                            val.fetchOptionHandler
                                                                        }
                                                                        searchPlaceholderText={
                                                                            val.searchPlaceholderText
                                                                        }
                                                                        cacheUniqs={
                                                                            multiSelectUniqCache[
                                                                                index
                                                                            ]?.uniqCache
                                                                        }
                                                                        extraField={
                                                                            multiStepOptionExtraField[
                                                                                index
                                                                            ]
                                                                                ?.extrafield
                                                                        }
                                                                    ></CartBasinMultiSelectFields>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Shapefiles</label>
                                        <div className="upload-btn-wrapper">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={() => {
                                                    dispatch(setShapeFileModal(true))

                                                    dispatch(showUploadModal());
                                                }}
                                            >
                                                <img src="images/upload.svg" alt="" />{" "}
                                                Upload Shapefiles
                                            </button>
                                        </div>
                                        <p className="text">
                                            Upload your shapefile to use as a filter
                                        </p>
                                    </div>


                                    <div className="form-group">
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index > 0 &&
                                                    index < 2 && (
                                                        <div
                                                            className={`col-md-${val.gridSize}`}
                                                            key={index}
                                                        >
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <CartBasinMultiSelectFields
                                                                index={index}
                                                                async={true}
                                                                isMulti={true}
                                                                name={val.name}
                                                                control={control}
                                                                defaultValue={[]}
                                                                itemsRef={itemsRef}
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]?.extrafield
                                                                }
                                                            />
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>

                                    {multiSelectProps.map(
                                        (val, index) =>
                                            index === 3 && (
                                                <div
                                                    className="form-group mb-0"
                                                    key={index}
                                                >
                                                    <label htmlFor="">API</label>
                                                    <div className="upload-wrapper">
                                                        <div className="api">
                                                            <CartBasinMultiSelectFields
                                                                index={index}
                                                                async={true}
                                                                name={val.name}
                                                                isMulti={true}
                                                                control={control}
                                                                defaultValue={[]}
                                                                itemsRef={itemsRef}
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]?.extrafield
                                                                }
                                                            ></CartBasinMultiSelectFields>
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                onClick={() => {
                                                                    dispatch(setShapeFileModal(false))
                                                                    dispatch(
                                                                        showUploadModal()
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src="images/upload.svg"
                                                                    alt=""
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "white",
                                        }}
                                    >
                                        or
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index === 10 && (
                                                        <div key={index}>
                                                            <label htmlFor=""></label>
                                                            <div className="upload-wrapper">
                                                                <div>
                                                                    <CartBasinMultiSelectFields
                                                                        index={index}
                                                                        async={true}
                                                                        isMulti={false}
                                                                        name={val.name}
                                                                        control={
                                                                            control
                                                                        }
                                                                        defaultValue={[]}
                                                                        itemsRef={
                                                                            itemsRef
                                                                        }
                                                                        showerror={
                                                                            val.showerror
                                                                        }
                                                                        placeholderText={
                                                                            val.placeholderText
                                                                        }
                                                                        fetchOptionHandler={
                                                                            val.fetchOptionHandler
                                                                        }
                                                                        searchPlaceholderText={
                                                                            val.searchPlaceholderText
                                                                        }
                                                                        cacheUniqs={
                                                                            multiSelectUniqCache[
                                                                                index
                                                                            ]?.uniqCache
                                                                        }
                                                                        extraField={
                                                                            multiStepOptionExtraField[
                                                                                index
                                                                            ]
                                                                                ?.extrafield
                                                                        }
                                                                    ></CartBasinMultiSelectFields>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                        <p className="text mt-0">
                                            Separate your APIs with a comma or upload
                                            CSV
                                        </p>
                                    </div>
                                    {multiSelectProps.map(
                                        (val, index) =>
                                            index > 3 &&
                                            index < 9 && (
                                                <div className="form-group" key={index}>
                                                    <div className="row">
                                                        <div
                                                            className={`col-md-${val.gridSize}`}
                                                        >
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <CartBasinMultiSelectFields
                                                                index={index}
                                                                async={true}
                                                                name={val.name}
                                                                isMulti={true}
                                                                control={control}
                                                                defaultValue={[]}
                                                                itemsRef={itemsRef}
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]?.extrafield
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )}


                                    {multiSelectProps.map(
                                        (val, index) =>
                                            index >= 20 &&
                                            index <= 21 && (
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div
                                                            className={`col-md-${val.gridSize}`}
                                                            key={index}
                                                        >
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <CartBasinMultiSelectFields
                                                                index={index}
                                                                async={true}
                                                                isMulti={true}
                                                                name={val.name}
                                                                control={control}
                                                                defaultValue={[]}
                                                                itemsRef={itemsRef}
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]?.extrafield
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )}

                                    {multiSelectProps.map(
                                        (val, index) =>
                                            index >= 17 &&
                                            index <= 19 && (
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div
                                                            className={`col-md-${val.gridSize}`}
                                                            key={index}
                                                        >
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <CartBasinMultiSelectFields
                                                                index={index}
                                                                async={true}
                                                                isMulti={true}
                                                                name={val.name}
                                                                control={control}
                                                                defaultValue={[]}
                                                                itemsRef={itemsRef}
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]?.extrafield
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )}
                                    <div
                                        className={`form-group ${(
                                            (Array.isArray(statekey) && statekey) ||
                                            []
                                        )
                                            .map((item) => item.label)
                                            .filter(
                                                (_item) =>
                                                    _item.toLowerCase() ===
                                                    "west virginia" ||
                                                    _item.toLowerCase() ===
                                                    "ohio" ||
                                                    _item.toLowerCase() ===
                                                    "pennsylvania"
                                            ).length > 0
                                            ? ""
                                            : "d-none"
                                            }`}
                                    >
                                        <div className="row">
                                            {inputFieldProps.map(
                                                (val, index) =>
                                                    index === 5 && (
                                                        <div
                                                            className="col-md-12"
                                                            key={index}
                                                        >
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <InputField
                                                                name={val.name}
                                                                type={val.type}
                                                                control={control}
                                                                maxLength={
                                                                    val.maxLength
                                                                }
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                className={
                                                                    val.className
                                                                }
                                                                placeholder={
                                                                    val.placeholder
                                                                }
                                                                defaultValue={
                                                                    val.defaultValue
                                                                }
                                                            />
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        className={`form-group d-none ${(
                                            (Array.isArray(statekey) && statekey) ||
                                            []
                                        )
                                            .map((item) => item.label)
                                            .filter(
                                                (_item) =>
                                                    _item.toLowerCase() ===
                                                    "west virginia" ||
                                                    _item.toLowerCase() ===
                                                    "pennsylvania"
                                            ).length > 0
                                            ? "d-none"
                                            : ""
                                            }`}
                                    >
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index === 12 && (
                                                        <div
                                                            className={`col-md-${val.gridSize}`}
                                                            key={index}
                                                        >
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <CartBasinMultiSelectFields
                                                                isMulti={true}
                                                                async={true}
                                                                index={index}
                                                                name={val.name}
                                                                control={control}
                                                                defaultValue={[]}
                                                                itemsRef={itemsRef}
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]?.extrafield
                                                                }
                                                            />
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>

                                    <div className={`form-group`}>
                                        <div className="row">
                                            {multiSelectProps.map(
                                                (val, index) =>
                                                    index === 9 && (
                                                        <div
                                                            className={`col-md-${val.gridSize}`}
                                                            key={index}
                                                        >
                                                            <label htmlFor="">
                                                                {val.label}
                                                            </label>
                                                            <CartBasinMultiSelectFields
                                                                isMulti={true}
                                                                async={true}
                                                                index={index}
                                                                name={val.name}
                                                                control={control}
                                                                defaultValue={[]}
                                                                itemsRef={itemsRef}
                                                                showerror={
                                                                    val.showerror
                                                                }
                                                                placeholderText={
                                                                    val.placeholderText
                                                                }
                                                                fetchOptionHandler={
                                                                    val.fetchOptionHandler
                                                                }
                                                                searchPlaceholderText={
                                                                    val.searchPlaceholderText
                                                                }
                                                                cacheUniqs={
                                                                    multiSelectUniqCache[
                                                                        index
                                                                    ]?.uniqCache
                                                                }
                                                                extraField={
                                                                    multiStepOptionExtraField[
                                                                        index
                                                                    ]?.extrafield
                                                                }
                                                            />
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>

                                    {sliderMinMaxValue.measured_depth ? (
                                        <SliderMinMax
                                            label="Measured Depth"
                                            name="depth"
                                            control={control}
                                            register={register}
                                            minInputName="minMeasuredDepth"
                                            maxInputName="maxMeasuredDepth"
                                            dataMinKey="minMeasuredDepth"
                                            dataMaxKey="maxMeasuredDepth"
                                            dataMaxValue="measured_depth"
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {sliderMinMaxValue.true_vertical_depth ? (
                                        <SliderMinMax
                                            label="True Vertical Depth"
                                            name="vertical_depth"
                                            control={control}
                                            register={register}
                                            minInputName="minTrueVerDepth"
                                            maxInputName="maxTrueVerDepth"
                                            dataMinKey="minTrueVerticalDepth"
                                            dataMaxKey="maxTrueVerticalDepth"
                                            dataMaxValue="true_vertical_depth"
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    {sliderMinMaxValue.lateral_length ? (
                                        <SliderMinMax
                                            label="Lateral Length"
                                            name="lateral_length"
                                            control={control}
                                            register={register}
                                            minInputName="minLateralDepth"
                                            maxInputName="maxLateralDepth"
                                            dataMinKey="minLateralLength"
                                            dataMaxKey="maxLateralLength"
                                            dataMaxValue="lateral_length"
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <CartBasinDate
                                        watch={watch}
                                        control={control}
                                        getValues={getValues}
                                        onChangeEndDate={onChangeEndDate}
                                        onChangeStartDate={onChangeStartDate}
                                    />
                                </div>
                            </Scrollbars>
                            <div className="button-action">
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={() => {
                                        reset();
                                        dispatch(setCartBasinFilterState(initialStateObj))
                                        setDefaultValue()
                                        dispatch(handlePageChange(1));
                                        onSubmit({ ...getValues() })
                                    }}
                                >
                                    <img src="images/filter-white.svg" alt="" />&nbsp;Reset
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={showTableLoader}
                                >
                                    Search
                                </button>
                            </div>
                        </form>
                    </div>

                    {uploadModal && (
                        <UploadFileModal
                            accept={shapeFileModal ? ["zip"] : ["csv"]}
                            control={control}
                            name={shapeFileModal ? "shapefiles" : "csvfiles"}
                            openModal={uploadModal}
                            label={
                                shapeFileModal
                                    ? "Upload your shapefiles"
                                    : "Upload your API list"
                            }
                            handleFileChange={handleFileChange}
                            modalCloseHandler={() => {
                                dispatch(hideUploadModal());
                            }}
                            extraContent={
                                !shapeFileModal ? (
                                    <a
                                        // className=""
                                        href={`${process.env.REACT_APP_ED_DATA_CDN_API}/sample-csv/api_upload_sample.csv`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Sample API csv file
                                    </a>
                                ) : (
                                    <></>
                                )
                            }
                        />
                    )}
                    <CartBasinModal
                        whichFileToOpen={fileToOpen}
                    />
                    <ExportToCsvModal
                        openExportOtherPopup={isExportOther}
                        openChooseColumnPopup={isChooseColumn}
                    />
                    {uploadingCsvApiListModal && (
                        <UploadingCsvApiListModal
                            fileName={csvApiFileName}
                            fileSize={csvApiFileSize}
                            handleOnClick={handleOnClick}
                            title="Upload your API list"
                            csvApiFileLoading={csvApiFileLoading}
                            handleCloseUploadApiListModal={() => {
                                dispatch(setCsvApiFileData([]))
                                dispatch(setCsvApiFileSize(0))
                                dispatch(setCsvApiFileName(""))
                                dispatch(setCsvApiFileLoading(true))
                                dispatch(toggleUploadingCsvApiListModal());
                            }}
                        />
                    )}
                    {apiUpgradeSubModal && (
                        <ApiUpgradeSubModal
                            handleConWithoutUpgrade={handleConWithoutUpgrade}
                            byBasinTabData={byBasinTabData}
                            byCountyTabData={byCountyTabData}
                        />
                    )}
                    {checkOutModal && (
                        <Elements stripe={stripePromise}>
                            <PaymentModal
                                onCancelBtnClick={() => {
                                    dispatch(hideCheckOutModal());
                                }}
                                isEdit={
                                    cartListItems.filter(
                                        (item) => item.subscription_det_id !== null
                                    ).length > 0
                                        ? true
                                        : false
                                }
                                removeBasinOrCounty={removeBasinOrCounty}
                                updateFilterAndUnmatchedDataAfterSub={
                                    updateFilterAndUnmatchedDataAfterSub
                                }
                            />
                        </Elements>
                    )}
                    {deleteCartItemModal && (
                        <DeleteConfirmationModal
                            show={deleteCartItemModal}
                            handleClose={() => {
                                dispatch(setDeleteItemId(null))
                                dispatch(setDeleteCartItemModal(false))
                                dispatch(setDeleteCartItemType(null))
                            }}
                            confirmBtnClick={() => {
                                deleteItemId &&
                                    dispatch(
                                        removeCartItems(access_token, {
                                            item_id: deleteItemId,
                                            item_type: deleteItemType as number,
                                            sub_total,
                                        })
                                    ).then((result) => {
                                        dispatch(getCartDetails(access_token));
                                        dispatch(setDeleteItemId(null))
                                        dispatch(setDeleteCartItemModal(false))
                                        dispatch(setDeleteCartItemType(null))
                                    });
                            }}
                            content={
                                <p>
                                    Are you sure you want to remove this item from the
                                    cart?
                                </p>
                            }
                        />
                    )}
                </div>
            }

            <AdvancedFilterCom />
        </>
    );
};

export default CartBasinFilterSection;
