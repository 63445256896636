import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Modal } from "react-bootstrap";
import {
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { useForm, useWatch } from "react-hook-form";
import {
    CheckOutDowngradeSeatFormData,
    CheckOutFormData,
    CheckOutSavedCardFormData,
} from "../../models/submit-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    CheckOutFormDowngradeSeatValidationSchema,
    CheckOutFormSavedCardValidationSchema,
    CheckOutFormValidationSchema,
    salesTaxIdRegex,
} from "../../../Helper/validation";
import { ADMIN_CONSTANT, MONTHS, USDollar, actionType, calculateDiscount, formatUSDCurrency, membersSettingsPathname, seatDiscountPercentage, setFormData, subscriptionPathname } from "../../../utils/helper";
import {
    calculateTax,
    cartSelectBasinCountyActions,
    clearCartItemsList,
    clearCartItemsTotalTax,
    clearSavedCardDetails,
    createSubscription,
    finalSubscription,
    getStateList,
    validatePromoCode,
} from "../../store/actions/cart-select-basin-county-actions";
import {
    hideCheckOutModal,
    hideSiteLoader,
    showSiteLoader,
} from "../../store/actions/modal-actions";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import LocationSearchInput from "../LocationSearchInput";
import { geocodeByAddress } from "react-places-autocomplete";
import {
    clearCompanySettingsDetails,
    fetchCompanySettingDetail,
} from "../../store/actions/company-settings-actions";
import { SelectInput } from "../SelectInput";
import RadioInputComponent from "../RadioInputComponent";
import CardNumberInput from "../CardNumberInput";
import CardExpiryInput from "../CardExpiryInput";
import CardCvcInput from "../CardCvcInput";
import moment from "moment";
import InputComponent from "../InputComponent";
import { cancelSubscription, clearSubscriptionData, fetchSubscriptionData, saveAndUpdateTaxExemptID } from "../../store/actions/subscription-settings-actions";
import { loadUser, logUserAction } from "../../store/actions/auth-actions";
import Accordion from 'react-bootstrap/Accordion';
import { clearProductionData, clearRigsData, clearWellsData, handlePageChange } from "../../store/actions/wells-rigs-action";
import { loadInviteMembers, membersSettingsActions } from "../../store/actions/members-setting-actions";

function PaymentModal({
    onCancelBtnClick,
    isEdit,
    removeBasinOrCounty,
    updateFilterAndUnmatchedDataAfterSub,
    seatModal,
}: {
    onCancelBtnClick: () => void;
    isEdit?: boolean;
    removeBasinOrCounty: (
        id: number,
        item_type: number,
        sub_total: number
    ) => void;
    updateFilterAndUnmatchedDataAfterSub?: () => void;
    seatModal?: boolean
}) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();


    const [stateData, setStateData] = useState<{
        address: string,
        calcTax: boolean,
        initialMount: boolean,
        newItemTotalInEditCase: number,
        newItemTotalTaxInEditCase: number,
        promoCodeValidating: boolean,
        taxExempt: boolean,
        showMembersDropDown: boolean,
        membersSelected: number[],
        downgradeValidation: boolean
    }>({
        address: "",
        calcTax: false,
        initialMount: true,
        newItemTotalInEditCase: 0,
        newItemTotalTaxInEditCase: 0,
        promoCodeValidating: false,
        taxExempt: false,
        showMembersDropDown: false,
        membersSelected: [],
        downgradeValidation: false
    });

    const {
        address,
        calcTax,
        initialMount,
        newItemTotalInEditCase,
        newItemTotalTaxInEditCase,
        promoCodeValidating,
        taxExempt,
        membersSelected,
        downgradeValidation
    } = stateData;
    const toggleMembersDropDown = () => setStateData((prev) => ({ ...prev, showMembersDropDown: !prev.showMembersDropDown }));
    const addRemoveMembers = (id: number) => {
        if (Number(seat_count) < allowedSubUser && membersSelected.length === Math.abs(Number(seat_count) - allowedSubUser) && !membersSelected.includes(id)) {
            toast.info("You are selecting more users than the downgraded seat value you have selected.", {
                toastId: "selecting_more_user_than_downgrading_seat"
            })
            return
        }
        if (membersSelected.includes(id)) {
            setStateData(prev => ({ ...prev, membersSelected: membersSelected.filter((item) => item !== id) }));
        } else {
            setStateData(prev => ({ ...prev, membersSelected: [...prev.membersSelected, id] }));
        }
    }



    const {
        modal: { checkOutModal },
        auth: {
            user: { first_name, last_name, access_token, company_configs: {
                free_trial_period_enabled,
                no_of_free_days_allowed,
            }, email },
        },
        companySettings: {
            address: { first_address, city, state, zip_code, phone_no },
            company: { billing_email, company_name, company_id },
        },
        cartSelectBasinCounty: {
            cartItemsTotal,
            stateOptions,
            cartListItems,
            cartItemsTotalTax,
            saved_card,
            tax_percentage,
            cartModified,
            promoCodeDetails: {
                is_valid,
                discount_percent,
                discount_fixed,
                minimum_amount,
                promoCode
            },
            yearly,
            yearly_discount_percent,
        },
        subscriptionSettings: { upgrade_subscription, loadSubscriptionData, subscriptionData },
        membersSettings: { inviteMemberDataLoading, inviteMembersData, }
    } = useAppSelector((state) => state);
    const stripe = useStripe();
    const elements = useElements();
    const allowedSubUser = subscriptionData?.subscription?.allowed_user || 1;
    let cartTotalCost = subscriptionData
        ? Number(
            subscriptionData.details
                // .filter((item) => !item.unsubscribe_status)
                .reduce(
                    (accumulator, currentValue) =>
                        accumulator + Number(currentValue.total_cost),
                    0
                )
                .toFixed(2)
        )
        : 0;
    let basinSubscribed = subscriptionData
        ?
        subscriptionData.details
            .filter((item) => item.line_item_type === 1)
        : [];
    let countySubscribed = subscriptionData
        ?
        subscriptionData.details
            .filter((item) => item.line_item_type === 2)
        : [];
    let goNationalSubscribed = subscriptionData
        ?
        subscriptionData.details
            .filter((item) => item.line_item_type === 3)
        : [];
    let basinTotalCost = basinSubscribed.length > 0
        ? Number(
            basinSubscribed
                .reduce(
                    (accumulator, currentValue) =>
                        accumulator + Number(currentValue.total_cost),
                    0
                )
                .toFixed(2)
        )
        : 0;
    let countyTotalCost = countySubscribed.length > 0
        ? Number(
            countySubscribed
                .reduce(
                    (accumulator, currentValue) =>
                        accumulator + Number(currentValue.total_cost),
                    0
                )
                .toFixed(2)
        )
        : 0;


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        control,
        watch,
        getValues
    } = useForm<CheckOutFormData | CheckOutSavedCardFormData | CheckOutDowngradeSeatFormData>({
        ...(saved_card.length === 0 && {
            resolver: yupResolver(CheckOutFormValidationSchema),
        }),
        ...(saved_card.length > 0 && {
            resolver: yupResolver(CheckOutFormSavedCardValidationSchema),
        }),
        ...(downgradeValidation && {
            resolver: yupResolver(CheckOutFormDowngradeSeatValidationSchema),
        }),
    });

    let seat_count = useWatch({ control, name: "seat_count" });

    const submitForm = async (
        data: CheckOutFormData | CheckOutSavedCardFormData | CheckOutDowngradeSeatFormData
    ) => {
        dispatch(showSiteLoader());
        try {
            let res;
            // create a payment method
            if (saved_card.length === 0 || watch("saved_card") === "0" && !downgradeValidation) {
                res = await stripe?.createPaymentMethod({
                    type: "card",
                    card: elements?.getElement(CardNumberElement)!,
                    billing_details: {
                        name: data.name,
                        email: data.billing_email,
                        address: {
                            line1: data.address,
                            city: data.city,
                            state: data.state,
                            postal_code: data.zip_code,
                            country: "US",
                        },
                    },
                });
            }
            const { paymentMethod, error } = res || {};
            if (error) {
                const { message } = error;
                toast.error(message);
                dispatch(hideSiteLoader());
                return;
            }
            // call the backend to create subscription
            if (
                (access_token && paymentMethod) ||
                (access_token && watch("saved_card")) ||
                (access_token && downgradeValidation)
            ) {
                dispatch(
                    createSubscription(
                        access_token,
                        (paymentMethod || watch("saved_card") === "0") && !downgradeValidation
                            ? {
                                billing_email: data.billing_email,
                                company_name: `${company_name}`,
                                payment_id: `${paymentMethod?.id}`,
                                company: company_id as number,
                                phone_no: phone_no,
                                last4: `${paymentMethod?.card?.last4}`,

                                exp_month: `${paymentMethod?.card?.exp_month}`,
                                exp_year: `${paymentMethod?.card?.exp_year}`,
                                brand: `${paymentMethod?.card?.brand}`,
                                name_on_card: data.name,
                                billing_cycle: yearly ? "yearly" : "monthly",
                                seat_count: Number(data.seat_count as string),
                                ...(seatModal && ({ new_item: false })),
                                ...(promoCode && { promo_code: promoCode }),
                            }
                            : downgradeValidation ? {
                                billing_email: billing_email,
                                company: company_id as number,
                                phone_no: phone_no,
                                billing_cycle: yearly ? "yearly" : "monthly",
                                seat_count: Number(data.seat_count as string),
                                remove_user: membersSelected,
                                new_item: false,
                            } : {
                                billing_email: data.billing_email,
                                saved_card_id: saved_card.filter(
                                    (item) =>
                                        Number(watch("saved_card")) ===
                                        item.id
                                )[0].id,
                                company: company_id as number,
                                phone_no: phone_no,
                                last4: saved_card.filter(
                                    (item) =>
                                        Number(watch("saved_card")) ===
                                        item.id
                                )[0].cc_no,
                                billing_cycle: yearly ? "yearly" : "monthly",
                                seat_count: Number(data.seat_count as string),
                                ...(seatModal && ({ new_item: false })),
                                ...(promoCode && { promo_code: promoCode })
                            }
                    )
                ).then(async (result) => {
                    if (result) {
                        const { status, data, msg } = result || {};
                        if (status === 200) {
                            if (data) {
                                const { client_secret } = data;
                                if (client_secret !== "na") {
                                    const confirmPayment =
                                        await stripe?.confirmCardPayment(
                                            client_secret
                                        );
                                    if (confirmPayment?.error) {
                                        toast.error(
                                            confirmPayment.error.message
                                        );
                                        dispatch(hideSiteLoader());
                                    } else {
                                        await dispatch(
                                            finalSubscription(access_token, {
                                                ...confirmPayment?.paymentIntent,
                                                ...(free_trial_period_enabled && { no_of_free_days_allowed })
                                            })
                                        );

                                        dispatch(hideCheckOutModal());
                                        //log user action
                                        let action_log_detail = "";
                                        cartListItems.map((_i, _indx) => "county_name" in _i ? (action_log_detail += `${_i.county_name} - ${_i.state_abbr} ${_indx === cartListItems.length - 1 ? "" : "|"}`) : (action_log_detail += `${_i.basin_name} ${_indx === cartListItems.length - 1 ? "" : "|"}`))
                                        dispatch(
                                            logUserAction({
                                                action_type: actionType['new_subscription'],
                                                action_log_detail
                                            })
                                        );

                                        toast.success(
                                            "Success! Check your email for the invoice."
                                        );
                                        // this function will call from api list upload in search page
                                        updateFilterAndUnmatchedDataAfterSub &&
                                            updateFilterAndUnmatchedDataAfterSub();

                                        //get the updated data of user after subscription   
                                        (
                                            upgrade_subscription || subscriptionData === null) && await dispatch(loadUser())

                                        dispatch(hideSiteLoader());
                                        seatModal && downgradeValidation && dispatch(membersSettingsActions.clearInviteMembers());
                                        subscriptionData !== null && dispatch(clearSubscriptionData())
                                        //Clear the existing table data to allow for a fresh reload. This will ensure that the new data, corresponding to the updated subscription, is properly loaded.
                                        dispatch(handlePageChange(1));
                                        dispatch(clearProductionData());
                                        dispatch(clearWellsData());
                                        dispatch(clearRigsData());
                                        navigate("/search");
                                    }
                                } else {
                                    dispatch(hideCheckOutModal());
                                    //get the updated data of user after subscription   
                                    (upgrade_subscription || subscriptionData === null) && await dispatch(loadUser())

                                    dispatch(hideSiteLoader());
                                    seatModal && downgradeValidation && dispatch(membersSettingsActions.clearInviteMembers());
                                    subscriptionData !== null && dispatch(clearSubscriptionData())
                                    //Clear the existing table data to allow for a fresh reload. This will ensure that the new data, corresponding to the updated subscription, is properly loaded.
                                    dispatch(handlePageChange(1));
                                    dispatch(clearProductionData());
                                    dispatch(clearWellsData());
                                    dispatch(clearRigsData());
                                    navigate("/search");
                                }
                            }
                        } else {
                            toast.error(msg);
                            dispatch(hideSiteLoader());
                        }
                    }
                })
            } else {
                dispatch(hideSiteLoader());
            }
        } catch (error) {
            dispatch(hideSiteLoader());
            console.log(error);
        }
    };

    const addressOnChange = (address: string) => {
        setStateData((prev) => ({ ...prev, address }));
    };

    const addressOnSelect = (address: string) => {
        let tempValue = address.split(",");
        let tempFirstAddress = [];
        for (let i = 1; i <= tempValue.length; i++) {
            switch (i) {
                case 1:
                    break;
                case 2:
                    setValue("state", tempValue[tempValue.length - i]?.trim());
                    break;
                case 3:
                    setValue("city", tempValue[tempValue.length - i]?.trim());
                    break;
                default:
                    tempFirstAddress.push(
                        tempValue[tempValue.length - i].trim()
                    );
            }
        }
        let formatedAddress = tempFirstAddress.reverse().join(",");
        setValue("address", formatedAddress);
        setStateData((prev) => ({
            ...prev,
            address: formatedAddress,
        }));
        geocodeByAddress(address)
            .then((results) => {
                const { address_components } = results[0];
                address_components.forEach((item) => {
                    if (item.types.includes("postal_code")) {
                        setValue("zip_code", item.long_name?.trim());
                    }
                });
            })
            .catch((error) => console.error("Error", error));
    };

    //closing the payment modal when no item is in list
    useLayoutEffect(() => {
        if (!cartModified && checkOutModal && !seatModal) {
            dispatch(hideCheckOutModal());
        }

        if (isEdit && cartListItems.length > 0) {
            let tempValue = cartListItems
                .filter(
                    (item) =>
                        item.is_deleted === false &&
                        item.subscription_det_id === null
                )
                .map((_item) => _item.price)
                .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                );
            setStateData((prev) => ({
                ...prev,
                newItemTotalInEditCase: Number(tempValue.toFixed(2)),
                newItemTotalTaxInEditCase: Number(
                    ((tempValue * tax_percentage) / 100).toFixed(2)
                ),
            }));
        }

        // eslint-disable-next-line
    }, [cartListItems, tax_percentage]);

    useLayoutEffect(() => {
        let calculateTaxPayload = {
            sub_total: seatModal ? cartTotalCost : yearly ? (cartItemsTotal - ((cartItemsTotal * yearly_discount_percent) / 100)) * MONTHS : cartItemsTotal,
            ...(calcTax && {
                first_address: watch("address"),
            }),
            ...(calcTax && {
                city: watch("city"),
            }),
            ...(calcTax && {
                state: watch("state"),
            }),
            ...(calcTax && {
                zip_code: watch("zip_code"),
            }),
            ...(calcTax && {
                country: watch("country"),
            }),
        };
        // if (((initialMount || calcTax) && !seatModal) || ((initialMount || calcTax) && seatModal && !loadSubscriptionData)) {
        if (initialMount || calcTax) {
            dispatch(
                calculateTax(access_token, calculateTaxPayload)
            ).finally(() => {
                dispatch(hideSiteLoader())
            });
            setStateData((prev) => ({
                ...prev,
                ...(calcTax && { calcTax: !calcTax }),
                ...(initialMount && { initialMount: !initialMount }),
            }));
        }
        // else if (access_token && loadSubscriptionData && seatModal) {
        //     dispatch(fetchSubscriptionData(access_token, true));
        // }
        // eslint-disable-next-line
    }, [calcTax,
        //  loadSubscriptionData
    ]);

    useEffect(() => {
        setFormData({
            seat_count: allowedSubUser
        }, setValue)
    }, [allowedSubUser])

    useEffect(() => {
        stateOptions.length === 0 && dispatch(getStateList(access_token));
        dispatch(fetchCompanySettingDetail(access_token, true));

        if (inviteMemberDataLoading && location.pathname === subscriptionPathname && seatModal) {
            dispatch(
                loadInviteMembers(access_token, {
                    keyword: "",
                }, true)
            );
        }
        return () => {
            dispatch(cartSelectBasinCountyActions.handlePromoCodeDetails({ reset: true }))
            dispatch(clearCartItemsTotalTax());
            dispatch(clearSavedCardDetails());
            dispatch(clearCompanySettingsDetails());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (company_id) {
            setStateData((prev) => ({
                ...prev,
                address: `${first_address}`,
            }));
            setFormData(
                {
                    name: `${first_name} ${last_name}`,
                    billing_email: billing_email,
                    address: `${first_address}`,
                    city,
                    zip_code,
                    country: "US",
                    state,
                },
                setValue
            );
        }
        // eslint-disable-next-line
    }, [company_id]);

    useEffect(() => {
        if (stateOptions.length > 0) setValue("state", state);
        // eslint-disable-next-line
    }, [stateOptions]);

    useEffect(() => {
        setStateData((prev) => ({
            ...prev,
            ...(prev.showMembersDropDown && { showMembersDropDown: !prev.showMembersDropDown }),
            ...(prev.membersSelected.length && {
                membersSelected: []
            }),
            downgradeValidation: seat_count && Number(seat_count) < allowedSubUser ? true : false

        }))
        if (seatModal) {
            if (Number(seat_count) > 10) {
                toast.info(<>
                    Please reach out to <a
                        href={`mailto:datasales@ed.com`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#fff" }}
                    >
                        datasales@energydomain.com
                    </a> to request more than 10 seats."
                </>, {
                    toastId: "greater_10_seat_info",
                })
                return
            };
            if (Number(seat_count) < 1) {
                toast.info(`Seat selection must be at least 1.`, {
                    toastId: "less_than_1_seat_Info"
                })
                return
            }
        }
        // eslint-disable-next-line
    }, [seat_count]);
    //total seat cost
    let totalSeatCost = Number((cartTotalCost * (Number(seat_count))).toFixed(2));
    //next billing cost after discount
    let nextBillingCost = totalSeatCost - Number(calculateDiscount(totalSeatCost, seatDiscountPercentage(seat_count as string)));
    let additionalSeatCost = (Number((cartTotalCost * (Number(seat_count) - allowedSubUser)).toFixed(2)));
    let additionalSeatCostDiscount = Number(calculateDiscount(additionalSeatCost, seatDiscountPercentage(seat_count as string)));
    let additionalSeatCostTax = Number(calculateDiscount((additionalSeatCost - additionalSeatCostDiscount), tax_percentage));

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;

        // Allow number keys, backspace, delete, tab, enter, and arrow keys
        if (
            (key >= '0' && key <= '9') ||
            key === 'Backspace'
            ||
            // key === 'Delete' ||
            key === 'Tab'
            // ||
            // key === 'Enter' ||
            // key === 'ArrowLeft' ||
            // key === 'ArrowRight'
        ) {
            // Temporarily get the input value if the key were to be pressed
            const newValue = e.currentTarget.value + key;

            // Convert new value to a number and check if it is in the range 1-10
            const numValue = Number(newValue);
            if (numValue < 1 || numValue > 10) {
                toast.info("Seats value must be greater than 1 and less than 10.")
                e.preventDefault(); // Prevent input if the value is out of range
            }
            return; // Allow the key press if valid
        }

        // Prevent all other keys
        e.preventDefault();
    };

    const adminMemberList = (inviteMembersData || []).filter(
        (item) =>
            item.signin_role ===
            ADMIN_CONSTANT && item.is_active
    )

    return (
        <>
            {!seatModal ?
                //checkout modal at cart page
                <Modal
                    className="commonModal paymentModal"
                    enforceFocus={false}
                    show={checkOutModal}
                >
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            reset();
                            onCancelBtnClick();
                        }}
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <form
                        className="form-block"
                        onSubmit={handleSubmit(submitForm)}
                        autoComplete="off"
                        autoCapitalize="off"
                    >
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>Billing information</h3>
                                    <div className="box">
                                        <div className="form-group">
                                            <InputComponent
                                                label="Billing email"
                                                name="billing_email"
                                                placeholder="Enter your billing email"
                                                register={register}
                                                errorMsg={
                                                    errors?.billing_email?.message as string
                                                }
                                            />
                                        </div>
                                        {Array.isArray(saved_card) &&
                                            saved_card.length > 0 && (
                                                <div className="form-group">
                                                    <label>Select payment method</label>
                                                    <div className="paymentOption">
                                                        {saved_card.map(
                                                            (item, index) => {
                                                                return (
                                                                    <div
                                                                        className="form-check"
                                                                        key={index}
                                                                    >
                                                                        <RadioInputComponent
                                                                            value={
                                                                                item.id
                                                                            }
                                                                            name={
                                                                                "saved_card"
                                                                            }
                                                                            register={
                                                                                register
                                                                            }
                                                                            id={`${item.id}`}
                                                                            label={`XXXX XXXX XXXX ${item.cc_no}`}
                                                                            labelClassName="form-check-label"
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                        <div className="form-check">
                                                            <RadioInputComponent
                                                                value={0}
                                                                name={"saved_card"}
                                                                register={register}
                                                                id={`0`}
                                                                label={`New Card`}
                                                                labelClassName="form-check-label"
                                                            />
                                                        </div>
                                                    </div>
                                                    <span className={`error`}>
                                                        {`${"saved_card" in errors
                                                            ? errors?.saved_card
                                                                ?.message
                                                            : ""
                                                            }`}
                                                    </span>
                                                </div>
                                            )}
                                        {(watch("saved_card") === "0" ||
                                            (Array.isArray(saved_card) &&
                                                saved_card.length === 0)) && (
                                                <>
                                                    <div className="form-group">
                                                        <label>Card details</label>
                                                        <div className="cardBrand">
                                                            <CardNumberInput
                                                                control={control}
                                                                name={"cardNumber"}
                                                            />
                                                        </div>
                                                        <span className="error">{`${"cardNumber" in errors
                                                            ? errors?.cardNumber
                                                                ?.message
                                                            : ""
                                                            }`}</span>
                                                        <div className="cardNum">
                                                            <div className="cardInput">
                                                                <CardExpiryInput
                                                                    control={control}
                                                                    name="cardExpiry"
                                                                    errorMsg={`${"cardExpiry" in
                                                                        errors
                                                                        ? errors
                                                                            ?.cardExpiry
                                                                            ?.message
                                                                        : ""
                                                                        }`}
                                                                />
                                                            </div>
                                                            <div className="cardInput">
                                                                <CardCvcInput
                                                                    name="cardCvc"
                                                                    errorMsg={`${"cardCvc" in errors
                                                                        ? errors
                                                                            ?.cardCvc
                                                                            ?.message
                                                                        : ""
                                                                        }`}
                                                                    control={control}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <InputComponent
                                                            label="Name on card"
                                                            name="name"
                                                            placeholder="Enter your name on card"
                                                            register={register}
                                                            errorMsg={errors?.name?.message as string}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Billing address </label>
                                                        <div className="selectInput mb-1">
                                                            {/* <i className="fa-solid fa-angle-down"></i> */}
                                                            <SelectInput
                                                                placeholder="Country"
                                                                name="country"
                                                                register={register}
                                                                options={[
                                                                    {
                                                                        label: "United States",
                                                                        value: "US",
                                                                    },
                                                                ]}
                                                                onBlur={(
                                                                    e: React.FocusEvent<
                                                                        HTMLInputElement,
                                                                        Element
                                                                    >
                                                                ) => {
                                                                    let value =
                                                                        e.target.value.trim();
                                                                    if (
                                                                        value &&
                                                                        value !== "US"
                                                                    ) {
                                                                        setStateData(
                                                                            (prev) => ({
                                                                                ...prev,
                                                                                calcTax:
                                                                                    true,
                                                                            })
                                                                        );
                                                                    }
                                                                }}
                                                                errorMsg={
                                                                    errors?.country?.message as string
                                                                }
                                                            />
                                                        </div>
                                                        <LocationSearchInput
                                                            name={`address`}
                                                            valueLoc={address}
                                                            errorMsg={
                                                                errors?.address?.message as string || ""
                                                            }
                                                            onChangeLoc={addressOnChange}
                                                            onSelect={addressOnSelect}
                                                            onBlur={(e) => {
                                                                let value =
                                                                    e.target.value.trim();
                                                                if (
                                                                    value &&
                                                                    value !== first_address
                                                                ) {
                                                                    setStateData(
                                                                        (prev) => ({
                                                                            ...prev,
                                                                            calcTax: true,
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                            control={control}
                                                            debounce={500}
                                                            searchOptions={{
                                                                componentRestrictions: {
                                                                    country: ["usa", "us"],
                                                                },
                                                                types: [],
                                                            }}
                                                            placeholder="Address"
                                                            shouldFetchSuggestions={
                                                                address?.length >= 3
                                                            }
                                                            title={address}
                                                        />
                                                        <div className="cardNum">
                                                            <div className="cardInput">
                                                                <InputComponent
                                                                    name="city"
                                                                    placeholder="City"
                                                                    register={register}
                                                                    errorMsg={
                                                                        errors.city?.message as string
                                                                    }
                                                                    onBlur={(
                                                                        e: React.FocusEvent<HTMLInputElement>
                                                                    ) => {
                                                                        let value =
                                                                            e.target.value.trim();
                                                                        if (
                                                                            value &&
                                                                            value !== city
                                                                        ) {
                                                                            setStateData(
                                                                                (prev) => ({
                                                                                    ...prev,
                                                                                    calcTax:
                                                                                        true,
                                                                                })
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="cardInput">
                                                                <InputComponent
                                                                    name="zip_code"
                                                                    placeholder="Zip code"
                                                                    register={register}
                                                                    errorMsg={
                                                                        errors.zip_code
                                                                            ?.message as string
                                                                    }
                                                                    onBlur={(
                                                                        e: React.FocusEvent<HTMLInputElement>
                                                                    ) => {
                                                                        let value =
                                                                            e.target.value.trim();
                                                                        if (
                                                                            value &&
                                                                            value !==
                                                                            zip_code
                                                                        ) {
                                                                            setStateData(
                                                                                (prev) => ({
                                                                                    ...prev,
                                                                                    calcTax:
                                                                                        true,
                                                                                })
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="selectInput mt-1">
                                                            {/* <i className="fa-solid fa-angle-down"></i> */}
                                                            <SelectInput
                                                                placeholder="State"
                                                                name="state"
                                                                register={register}
                                                                options={stateOptions}
                                                                errorMsg={
                                                                    errors.state?.message as string
                                                                }
                                                                onBlur={(
                                                                    e: React.FocusEvent<
                                                                        HTMLInputElement,
                                                                        Element
                                                                    >
                                                                ) => {
                                                                    let value =
                                                                        e.target.value.trim();
                                                                    setValue('state', value);
                                                                    if (
                                                                        value &&
                                                                        value !== state
                                                                    ) {
                                                                        setStateData(
                                                                            (prev) => ({
                                                                                ...prev,
                                                                                calcTax:
                                                                                    true,
                                                                            })
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>

                                    <div className={"promoCode"}>
                                        <div className="custom-checkbox mb-4">
                                            <InputComponent
                                                name="taxExempt"
                                                className="checkmark"
                                                type="checkbox"
                                                id="taxExempt"
                                                register={register}
                                                errorMsg={
                                                    errors.taxExempt?.message as string
                                                }
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setValue('taxExempt', e.target.checked);
                                                    setStateData((prev) => ({ ...prev, taxExempt: e.target.checked }));
                                                }}
                                            />
                                            <label htmlFor="taxExempt" className="custom-label"> My organization is tax exempt</label>
                                        </div>
                                        {taxExempt ? <div className={"form-group"}>
                                            <InputComponent
                                                label="Sales tax exemption"
                                                htmlFor="sales_tax_id"
                                                labelClassName="salesTaxExemption"
                                                name="sales_tax_id"
                                                id="sales_tax_id"
                                                placeholder="Enter your Tax ID"
                                                register={register}
                                                errorMsg={
                                                    errors.sales_tax_id?.message as string
                                                }
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setValue('sales_tax_id', e.target.value)
                                                }}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                                                    let value = e.target.value.trim();
                                                    const isValid = salesTaxIdRegex.test(value);
                                                    if (!isValid && value) {
                                                        toast.error(`The sales tax id is invalid.`, {
                                                            toastId: "tax_id_is_invalid",
                                                        })
                                                        return;
                                                    };
                                                    value && dispatch(saveAndUpdateTaxExemptID(value))
                                                }}
                                            />
                                        </div> : <></>}
                                        {Number(seat_count) > allowedSubUser ? <></> : <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    Add promo code
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="promoCodeinfo">
                                                        <div className={`addpromoForm ${is_valid === "Valid" ? "d-none" : ""}`}>
                                                            {/* <input type="text" {...register("promoCode")} className="form-control" placeholder="Enter code" /> */}
                                                            <InputComponent
                                                                name="promoCode"
                                                                placeholder="Enter code"
                                                                register={register}
                                                            />
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    if (!getValues('promoCode')) {
                                                                        toast.info("Please enter promo code.")
                                                                        return
                                                                    }
                                                                    if (promoCodeValidating) {
                                                                        return
                                                                    }
                                                                    if (getValues('promoCode')) {
                                                                        setStateData((prev) => ({ ...prev, promoCodeValidating: true }));
                                                                        dispatch(validatePromoCode(access_token, { promo_code: getValues('promoCode') as string, cartTotal: cartItemsTotal })).finally(() => {
                                                                            setValue('promoCode', "")
                                                                            setStateData((prev) => ({ ...prev, promoCodeValidating: false }));
                                                                        })
                                                                    }

                                                                }}
                                                            >{promoCodeValidating ? "Validating Promo Code" : "Apply"}</button>
                                                        </div>
                                                        <div className={is_valid === "Valid" ? "blastOff" : "d-none"}>
                                                            <i className="fa-solid fa-circle-check"></i>
                                                            <div className="blast-info">
                                                                {promoCode}
                                                                <span>{`${discount_percent ? `${discount_percent}% ` : ""}`}discount (-{discount_percent ? (Number(calculateDiscount(
                                                                    (Number((cartItemsTotal * (yearly ? MONTHS : 1)).toFixed(2))
                                                                        -
                                                                        (yearly
                                                                            ?
                                                                            Number(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100).toFixed(2))
                                                                            :
                                                                            0)),
                                                                    (discount_percent as number)))) : discount_fixed || minimum_amount || 0})</span>
                                                            </div>
                                                            <i className="fa-solid fa-xmark" onClick={() => {
                                                                dispatch(cartSelectBasinCountyActions.handlePromoCodeDetails({ reset: true }))
                                                            }}></i>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h3>Order Summary</h3>
                                    <div className="box p-0">
                                        <div className="price-list scrollSection">
                                            <ul>
                                                {isEdit && (
                                                    <li className="header">
                                                        <div className="content">
                                                            <div className="small-map">
                                                                &nbsp;
                                                            </div>
                                                            <div className="description">
                                                                Geographic Area
                                                            </div>
                                                        </div>
                                                        <div className="price">
                                                            Recurring Totals
                                                        </div>
                                                        <div className="price">
                                                            Subtotal
                                                        </div>
                                                    </li>
                                                )}
                                                {cartListItems.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="content">
                                                            {item.subscription_det_id ===
                                                                null && (
                                                                    <a
                                                                        className="cross-btn"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            let sub_total =
                                                                                cartItemsTotal -
                                                                                item.price;
                                                                            removeBasinOrCounty(
                                                                                item.id,
                                                                                "basin_name" in
                                                                                    item
                                                                                    ? 1
                                                                                    : 2,
                                                                                sub_total
                                                                            );
                                                                        }}
                                                                        href="void(0)"
                                                                    >
                                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                                    </a>
                                                                )}
                                                            <div className="small-map">
                                                                <img
                                                                    src={"plan_name" in item && item.plan_name ? `${process.env.REACT_APP_ED_DATA_CDN_API}/company_logo/${item.png}` : item.png}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="description">
                                                                <h4>
                                                                    {"plan_name" in item && item.plan_name ? item.plan_name : "basin_name" in
                                                                        item
                                                                        ? `${item.basin_name}`
                                                                        : `${item.county_name}`}
                                                                </h4>
                                                                <p
                                                                    className={
                                                                        isEdit
                                                                            ? item.is_deleted
                                                                                ? "red"
                                                                                : item.subscription_det_id
                                                                                    ? ""
                                                                                    : "green"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {isEdit === false
                                                                        ? `${yearly ? "Yearly" : "Monthly"} subscription`
                                                                        : item.is_deleted
                                                                            ? `Cancelled Active Untill: ${moment(
                                                                                item.end_period
                                                                            ).format(
                                                                                "MMM-DD-YYYY"
                                                                            )}`
                                                                            : item.subscription_det_id ===
                                                                                null
                                                                                ? `New ${yearly ? "yearly" : "monthly"} subscription`
                                                                                : `Current ${yearly ? "yearly" : "monthly"} subscription`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="price">
                                                            {item.is_deleted === true
                                                                ? USDollar.format(0)
                                                                : yearly ? formatUSDCurrency(item.price * MONTHS) : USDollar.format(
                                                                    item.price
                                                                )}
                                                        </div>
                                                        {isEdit && (
                                                            <div className="price">
                                                                {item.subscription_det_id !==
                                                                    null
                                                                    ? USDollar.format(0)
                                                                    : USDollar.format(
                                                                        item.price
                                                                    )}
                                                            </div>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="total-block">
                                            <ul className="subtotal">
                                                <li>
                                                    <label>Subtotal </label>
                                                    <span>
                                                        {USDollar.format(
                                                            cartItemsTotal * (yearly ? MONTHS : 1)
                                                        )}
                                                    </span>
                                                    {isEdit && (
                                                        <span>
                                                            {USDollar.format(
                                                                newItemTotalInEditCase * (yearly ? MONTHS : 1)
                                                            )}
                                                        </span>
                                                    )}
                                                </li>
                                                <li className={`${yearly ? "" : "d-none"}`}>
                                                    <label>Discount</label>
                                                    <span className="red">{USDollar.format(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100))}</span>
                                                </li>
                                                <li className={`${is_valid === "Valid" ? "" : "d-none"}`}>
                                                    <label>Promotion/Coupon discount</label>
                                                    <span className="red">{formatUSDCurrency(discount_percent ? (Number(calculateDiscount(
                                                        (Number((cartItemsTotal * (yearly ? MONTHS : 1)).toFixed(2))
                                                            -
                                                            (yearly
                                                                ?
                                                                Number(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100).toFixed(2))
                                                                :
                                                                0)),
                                                        (discount_percent as number)))) : (discount_fixed as number))}</span>
                                                </li>
                                                <li>
                                                    <label>Taxes </label>
                                                    <span>
                                                        {USDollar.format(
                                                            is_valid === "Valid" ? Number(calculateDiscount((cartItemsTotal * (yearly ? MONTHS : 1)) -
                                                                ((cartItemsTotal * MONTHS * yearly_discount_percent) / 100)
                                                                - (discount_percent ? (Number(calculateDiscount(
                                                                    (Number((cartItemsTotal * (yearly ? MONTHS : 1)).toFixed(2))
                                                                        -
                                                                        (yearly
                                                                            ?
                                                                            Number(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100).toFixed(2))
                                                                            :
                                                                            0)),
                                                                    (discount_percent as number)))) : (discount_fixed as number)), tax_percentage)) : cartItemsTotalTax
                                                        )}
                                                    </span>
                                                    {isEdit && (
                                                        <span>
                                                            {newItemTotalTaxInEditCase
                                                                ? USDollar.format(
                                                                    newItemTotalTaxInEditCase
                                                                )
                                                                : USDollar.format(0)}
                                                        </span>
                                                    )}
                                                </li>
                                                {isEdit && (
                                                    <li className="monthly">
                                                        <label>
                                                            {`${yearly ? "Annually" : "Monthly"}`} Recurring Total{" "}
                                                        </label>
                                                        <span>
                                                            {USDollar.format(
                                                                cartItemsTotalTax +
                                                                cartItemsTotal
                                                            )}
                                                        </span>
                                                        <span>&nbsp;</span>
                                                    </li>
                                                )}
                                            </ul>
                                            <div className="total">
                                                <label>
                                                    {isEdit
                                                        ? "Total Due Today"
                                                        : "Billed now:"}
                                                </label>
                                                <span>
                                                    {isEdit
                                                        ? USDollar.format(
                                                            newItemTotalTaxInEditCase +
                                                            newItemTotalInEditCase
                                                        )
                                                        :
                                                        // yearly ?
                                                        formatUSDCurrency(
                                                            // Number(USDollar.format(cartItemsTotal * (yearly ? MONTHS : 1)).replace("$", ""))
                                                            // -
                                                            // Number(USDollar.format(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100)).replace("$", ""))
                                                            // -
                                                            // (is_valid === "Valid" ? Number(formatUSDCurrency(discount_percent ? (Number(calculateDiscount(cartItemsTotal * (yearly ? MONTHS : 1), (discount_percent as number)))) : (discount_fixed as number)).replace("$", "")) : 0)
                                                            // +
                                                            // Number((cartItemsTotalTax).toFixed(2))

                                                            Number((cartItemsTotal * (yearly ? MONTHS : 1)).toFixed(2))
                                                            -
                                                            (yearly ? Number(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100).toFixed(2)) : 0)
                                                            -
                                                            (is_valid === "Valid" ? (discount_percent ? (Number(calculateDiscount(
                                                                (Number((cartItemsTotal * (yearly ? MONTHS : 1)).toFixed(2))
                                                                    -
                                                                    (yearly
                                                                        ?
                                                                        Number(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100).toFixed(2))
                                                                        :
                                                                        0)),
                                                                (discount_percent as number)))) : (discount_fixed as number)) : 0)
                                                            +
                                                            (
                                                                is_valid === "Valid" ? Number(calculateDiscount((cartItemsTotal * (yearly ? MONTHS : 1)) -
                                                                    ((cartItemsTotal * MONTHS * yearly_discount_percent) / 100)
                                                                    - (discount_percent ? (Number(calculateDiscount(
                                                                        (Number((cartItemsTotal * (yearly ? MONTHS : 1)).toFixed(2))
                                                                            -
                                                                            (yearly
                                                                                ?
                                                                                Number(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100).toFixed(2))
                                                                                :
                                                                                0)),
                                                                        (discount_percent as number)))) : (discount_fixed as number)), tax_percentage)) : cartItemsTotalTax
                                                            )
                                                        )

                                                        // : USDollar.format(
                                                        //     cartItemsTotal +
                                                        //     cartItemsTotalTax
                                                        // )
                                                    }
                                                </span>
                                            </div>
                                            {!isEdit ? (
                                                <button
                                                    type="submit"
                                                    className={`btn btn-primary width100`}
                                                >
                                                    Subscribe
                                                </button>
                                            ) : (
                                                <div className="action-btn">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline"
                                                        onClick={() =>
                                                            dispatch(
                                                                hideCheckOutModal()
                                                            )
                                                        }
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type={
                                                            cartListItems.filter(
                                                                (item) =>
                                                                    item.is_deleted ===
                                                                    true
                                                            ).length ===
                                                                cartListItems.length
                                                                ? "button"
                                                                : "submit"
                                                        }
                                                        onClick={() => {
                                                            cartListItems.filter(
                                                                (item) =>
                                                                    item.is_deleted ===
                                                                    true
                                                            ).length ===
                                                                cartListItems.length &&
                                                                dispatch(
                                                                    cancelSubscription(
                                                                        access_token
                                                                    )
                                                                ).then((result) => {
                                                                    if (result) {
                                                                        const {
                                                                            status,
                                                                            msg,
                                                                        } = result;
                                                                        if (
                                                                            status ===
                                                                            200
                                                                        ) {
                                                                            toast.success(
                                                                                msg
                                                                            );
                                                                            dispatch(
                                                                                clearCartItemsList()
                                                                            );
                                                                            dispatch(
                                                                                hideCheckOutModal()
                                                                            );
                                                                        } else {
                                                                            toast.error(
                                                                                msg
                                                                            );
                                                                        }
                                                                    }
                                                                });
                                                        }}
                                                        className={`btn btn-primary`}
                                                    >
                                                        {cartListItems.filter(
                                                            (item) =>
                                                                item.is_deleted === true
                                                        ).length ===
                                                            cartListItems.length
                                                            ? "Cancel Subscription"
                                                            : "Update Subscription"}{" "}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </form>
                </Modal>
                :
                //seat count modal
                <Modal
                    className="commonModal paymentModal"
                    enforceFocus={false}
                    show={checkOutModal}
                >
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            reset();
                            onCancelBtnClick();
                        }}
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <form
                        className="form-block"
                        onSubmit={handleSubmit(submitForm)}
                        autoComplete="off"
                        autoCapitalize="off"
                    >
                        <Modal.Body>
                            <div className="row" onClick={() => {
                                stateData["showMembersDropDown"] && setStateData((prev) => ({ ...prev, showMembersDropDown: false }))
                            }}>
                                <div className="col-md-6">
                                    <h3>Edit Seats</h3>
                                    <div className="seatsBlock">
                                        <div className="currentseats">
                                            Current Seats
                                            <strong>{allowedSubUser}
                                                <span className={Number(seat_count) > allowedSubUser ? "" : "d-none"}>+{Number(seat_count) - allowedSubUser}
                                                </span> <span className={seat_count && Number(seat_count) < allowedSubUser ? "red-clr" : "d-none"}>{Number(seat_count) - allowedSubUser}
                                                </span></strong>
                                        </div>
                                        <div className="needseats">
                                            <h5>How many seats do you need?</h5>
                                            <div className="needform">
                                                <button type="button" className="btn btn-primary active" onClick={() => {
                                                    if (seat_count) {
                                                        if (Number(seat_count) <= 1) {
                                                            toast.info(`Seat selection must be at least 1.`, {
                                                                toastId: "less_than_1_seat"
                                                            })
                                                            return
                                                        }
                                                        setStateData((prev) => ({
                                                            ...prev,
                                                            ...(prev.showMembersDropDown && { showMembersDropDown: !prev.showMembersDropDown }),
                                                            ...(prev.membersSelected.length && {
                                                                membersSelected: []
                                                            })
                                                        }));
                                                        setValue("seat_count", `${Number(seat_count) - 1}`)
                                                    }
                                                }}><i className="fa-solid fa-minus"></i></button>
                                                <InputComponent
                                                    name="seat_count"
                                                    register={register}
                                                    onKeyDown={handleKeyDown}
                                                    className={(Number(seat_count) < allowedSubUser && Number(seat_count) >= 1) ? "highlight" : ""}
                                                />
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        if (seat_count) {
                                                            if (Number(seat_count) >= 10) {
                                                                toast.info(<>
                                                                    Please reach out to <a
                                                                        href={`mailto:datasales@ed.com`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ color: "#fff" }}
                                                                    >
                                                                        datasales@energydomain.com
                                                                    </a> to request more than 10 seats."
                                                                </>, {
                                                                    toastId: "greater_10_seat",
                                                                })
                                                                return
                                                            }
                                                            setStateData((prev) => ({
                                                                ...prev,
                                                                ...(prev.showMembersDropDown && { showMembersDropDown: !prev.showMembersDropDown }),
                                                                ...(prev.membersSelected.length && {
                                                                    membersSelected: []
                                                                })
                                                            }))
                                                            setValue("seat_count", `${Number(seat_count) + 1}`)
                                                        }
                                                    }} ><i className="fa-solid fa-plus"></i></button>
                                            </div>
                                        </div>

                                    </div>
                                    <h3>Payment Method</h3>
                                    <>
                                        {Number(seat_count) < allowedSubUser && Number(seat_count) >= 1 ? <div className="box warningBox">
                                            <div className="warningBlock">
                                                <div className="icon"><img src="../images/warning.svg" alt="" /></div>
                                                <div className="content">To downgrade to this seat count, you must first select a team members and/or pending invites from your current plan to be removed at the end of your billing cycle.</div>
                                            </div>
                                            <div className="selectBlock show">
                                                <div className={`selectInput ${stateData['showMembersDropDown'] ? "show" : ""}`}>
                                                    <input type="button" className="form-control" value="Select a team member" onClick={toggleMembersDropDown} />
                                                    <div className="slectdropDown scrollSection">
                                                        <ul>
                                                            {
                                                                (inviteMembersData || [])?.filter(_members => _members.is_active).map((members, index) => {
                                                                    return <li key={index + members['id']} onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleMembersDropDown()
                                                                    }}>
                                                                        <div className="checkblock">
                                                                            <div className="custom-checkbox">
                                                                                <input className="form-control checkmark"
                                                                                    checked={membersSelected.includes(members['id']) ? true : false}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        if (
                                                                                            members.signin_role ===
                                                                                            ADMIN_CONSTANT &&
                                                                                            (adminMemberList.length === 1 || adminMemberList.filter((adminMember) => membersSelected.includes(adminMember.id) && adminMember.id !== members['id']).length)
                                                                                        ) {
                                                                                            toast.info(
                                                                                                `Please assign the admin role to another member before deactivating your account.`, {
                                                                                                toastId: "one_admin"
                                                                                            }
                                                                                            );
                                                                                            return;
                                                                                        }
                                                                                        addRemoveMembers(members['id'])
                                                                                    }} type="checkbox" id={`${members['id']}`} />
                                                                                <label onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                }} htmlFor={`${members['id']}`} className="custom-label"> {members['name']} {members['email'] === email ? <span>(you)</span> : <></>}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="warning-name">{members['signin_role']}</div>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            :
                                            <div className="box">
                                                <div className="boxScroll scrollSection">
                                                    {Array.isArray(saved_card) &&
                                                        saved_card.length > 0 && (
                                                            <div className="form-group">
                                                                <label>Select payment method</label>
                                                                <div className="paymentOption">
                                                                    {saved_card.map(
                                                                        (item, index) => {
                                                                            return (
                                                                                <div
                                                                                    className="form-check"
                                                                                    key={index}
                                                                                >
                                                                                    <RadioInputComponent
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                        name={
                                                                                            "saved_card"
                                                                                        }
                                                                                        register={
                                                                                            register
                                                                                        }
                                                                                        id={`${item.id}`}
                                                                                        label={`XXXX XXXX XXXX ${item.cc_no}`}
                                                                                        labelClassName="form-check-label"
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                    <div className="form-check">
                                                                        <RadioInputComponent
                                                                            value={0}
                                                                            name={"saved_card"}
                                                                            register={register}
                                                                            id={`0`}
                                                                            label={`New Card`}
                                                                            labelClassName="form-check-label"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <span className={`error`}>
                                                                    {`${"saved_card" in errors
                                                                        ? errors?.saved_card
                                                                            ?.message
                                                                        : ""
                                                                        }`}
                                                                </span>
                                                            </div>
                                                        )}
                                                    {(watch("saved_card") === "0" ||
                                                        (Array.isArray(saved_card) &&
                                                            saved_card.length === 0)) && (
                                                            <>
                                                                <div className="form-group">
                                                                    <label>Card details</label>
                                                                    <div className="cardBrand">
                                                                        <CardNumberInput
                                                                            control={control}
                                                                            name={"cardNumber"}
                                                                        />
                                                                    </div>
                                                                    <span className="error">{`${"cardNumber" in errors
                                                                        ? errors?.cardNumber
                                                                            ?.message
                                                                        : ""
                                                                        }`}</span>
                                                                    <div className="cardNum">
                                                                        <div className="cardInput">
                                                                            <CardExpiryInput
                                                                                control={control}
                                                                                name="cardExpiry"
                                                                                errorMsg={`${"cardExpiry" in
                                                                                    errors
                                                                                    ? errors
                                                                                        ?.cardExpiry
                                                                                        ?.message
                                                                                    : ""
                                                                                    }`}
                                                                            />
                                                                        </div>
                                                                        <div className="cardInput">
                                                                            <CardCvcInput
                                                                                name="cardCvc"
                                                                                errorMsg={`${"cardCvc" in errors
                                                                                    ? errors
                                                                                        ?.cardCvc
                                                                                        ?.message
                                                                                    : ""
                                                                                    }`}
                                                                                control={control}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group">
                                                                    <InputComponent
                                                                        label="Name on card"
                                                                        name="name"
                                                                        placeholder="Enter your name on card"
                                                                        register={register}
                                                                        errorMsg={errors?.name?.message as string}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Billing address </label>
                                                                    <div className="selectInput mb-1">
                                                                        <SelectInput
                                                                            placeholder="Country"
                                                                            name="country"
                                                                            register={register}
                                                                            options={[
                                                                                {
                                                                                    label: "United States",
                                                                                    value: "US",
                                                                                },
                                                                            ]}
                                                                            onBlur={(
                                                                                e: React.FocusEvent<
                                                                                    HTMLInputElement,
                                                                                    Element
                                                                                >
                                                                            ) => {
                                                                                let value =
                                                                                    e.target.value.trim();
                                                                                if (
                                                                                    value &&
                                                                                    value !== "US"
                                                                                ) {
                                                                                    setStateData(
                                                                                        (prev) => ({
                                                                                            ...prev,
                                                                                            calcTax:
                                                                                                true,
                                                                                        })
                                                                                    );
                                                                                }
                                                                            }}
                                                                            errorMsg={
                                                                                errors?.country?.message as string
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <LocationSearchInput
                                                                        name={`address`}
                                                                        valueLoc={address}
                                                                        errorMsg={
                                                                            errors?.address?.message as string ||
                                                                            ""
                                                                        }
                                                                        onChangeLoc={addressOnChange}
                                                                        onSelect={addressOnSelect}
                                                                        onBlur={(e) => {
                                                                            let value =
                                                                                e.target.value.trim();
                                                                            if (
                                                                                value &&
                                                                                value !== first_address
                                                                            ) {
                                                                                setStateData(
                                                                                    (prev) => ({
                                                                                        ...prev,
                                                                                        calcTax: true,
                                                                                    })
                                                                                );
                                                                            }
                                                                        }}
                                                                        control={control}
                                                                        debounce={500}
                                                                        searchOptions={{
                                                                            componentRestrictions: {
                                                                                country: ["usa", "us"],
                                                                            },
                                                                            types: [],
                                                                        }}
                                                                        placeholder="Address"
                                                                        shouldFetchSuggestions={
                                                                            address?.length >= 3
                                                                        }
                                                                        title={address}
                                                                    />
                                                                    <div className="cardNum">
                                                                        <div className="cardInput">
                                                                            <InputComponent
                                                                                name="city"
                                                                                placeholder="City"
                                                                                register={register}
                                                                                errorMsg={
                                                                                    errors.city?.message as string
                                                                                }
                                                                                onBlur={(
                                                                                    e: React.FocusEvent<HTMLInputElement>
                                                                                ) => {
                                                                                    let value =
                                                                                        e.target.value.trim();
                                                                                    if (
                                                                                        value &&
                                                                                        value !== city
                                                                                    ) {
                                                                                        setStateData(
                                                                                            (prev) => ({
                                                                                                ...prev,
                                                                                                calcTax:
                                                                                                    true,
                                                                                            })
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="cardInput">
                                                                            <InputComponent
                                                                                name="zip_code"
                                                                                placeholder="Zip code"
                                                                                register={register}
                                                                                errorMsg={
                                                                                    errors.zip_code
                                                                                        ?.message as string
                                                                                }
                                                                                onBlur={(
                                                                                    e: React.FocusEvent<HTMLInputElement>
                                                                                ) => {
                                                                                    let value =
                                                                                        e.target.value.trim();
                                                                                    if (
                                                                                        value &&
                                                                                        value !==
                                                                                        zip_code
                                                                                    ) {
                                                                                        setStateData(
                                                                                            (prev) => ({
                                                                                                ...prev,
                                                                                                calcTax:
                                                                                                    true,
                                                                                            })
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="selectInput mt-1">
                                                                        <SelectInput
                                                                            placeholder="State"
                                                                            name="state"
                                                                            register={register}
                                                                            options={stateOptions}
                                                                            errorMsg={
                                                                                errors.state?.message as string
                                                                            }
                                                                            onBlur={(
                                                                                e: React.FocusEvent<
                                                                                    HTMLInputElement,
                                                                                    Element
                                                                                >
                                                                            ) => {
                                                                                let value =
                                                                                    e.target.value.trim();
                                                                                setValue('state', value);
                                                                                if (
                                                                                    value &&
                                                                                    value !== state
                                                                                ) {
                                                                                    setStateData(
                                                                                        (prev) => ({
                                                                                            ...prev,
                                                                                            calcTax:
                                                                                                true,
                                                                                        })
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    <div className={"promoCode"}>
                                                        <div className="custom-checkbox mb-4">
                                                            <InputComponent
                                                                name="taxExempt"
                                                                className="checkmark"
                                                                type="checkbox"
                                                                id="taxExempt"
                                                                register={register}
                                                                errorMsg={
                                                                    errors.taxExempt?.message as string
                                                                }
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setValue('taxExempt', e.target.checked);
                                                                    setStateData((prev) => ({ ...prev, taxExempt: e.target.checked }));
                                                                }}
                                                            />
                                                            <label htmlFor="taxExempt" className="custom-label"> My organization is tax exempt</label>
                                                        </div>
                                                        {taxExempt ? <div className={"form-group"}>
                                                            <InputComponent
                                                                label="Sales tax exemption"
                                                                htmlFor="sales_tax_id"
                                                                labelClassName="salesTaxExemption"
                                                                name="sales_tax_id"
                                                                id="sales_tax_id"
                                                                placeholder="Enter your Tax ID"
                                                                register={register}
                                                                errorMsg={
                                                                    errors.sales_tax_id?.message as string
                                                                }
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setValue('sales_tax_id', e.target.value)
                                                                }}
                                                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                                                                    let value = e.target.value.trim();
                                                                    const isValid = salesTaxIdRegex.test(value);
                                                                    if (!isValid && value) {
                                                                        toast.error(`The sales tax id is invalid.`, {
                                                                            toastId: "tax_id_is_invalid",
                                                                        })
                                                                        return;
                                                                    };
                                                                    value && dispatch(saveAndUpdateTaxExemptID(value))
                                                                }}
                                                            />
                                                        </div> : <></>}
                                                        {Number(seat_count) > allowedSubUser ? <></> : <Accordion defaultActiveKey="0">
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                    Add promo code
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="promoCodeinfo">
                                                                        <div className={`addpromoForm ${is_valid === "Valid" ? "d-none" : ""}`}>
                                                                            <InputComponent
                                                                                name="promoCode"
                                                                                placeholder="Enter code"
                                                                                register={register}
                                                                            />
                                                                            <button type="button" className="btn btn-primary"
                                                                                onClick={() => {
                                                                                    if (!getValues('promoCode')) {
                                                                                        toast.info("Please enter promo code.")
                                                                                        return
                                                                                    }
                                                                                    if (promoCodeValidating) {
                                                                                        return
                                                                                    }
                                                                                    if (getValues('promoCode')) {
                                                                                        setStateData((prev) => ({ ...prev, promoCodeValidating: true }));
                                                                                        dispatch(validatePromoCode(access_token, { promo_code: getValues('promoCode') as string, cartTotal: cartItemsTotal })).finally(() => {
                                                                                            setValue('promoCode', "")
                                                                                            setStateData((prev) => ({ ...prev, promoCodeValidating: false }));
                                                                                        })
                                                                                    }

                                                                                }}
                                                                            >{promoCodeValidating ? "Validating Promo Code" : "Apply"}</button>
                                                                        </div>
                                                                        <div className={is_valid === "Valid" ? "blastOff" : "d-none"}>
                                                                            <i className="fa-solid fa-circle-check"></i>
                                                                            <div className="blast-info">
                                                                                {promoCode}
                                                                                <span>{`${discount_percent ? `${discount_percent}% ` : ""}`}discount (-{discount_percent ? (Number(calculateDiscount(
                                                                                    (Number((cartItemsTotal * (yearly ? MONTHS : 1)).toFixed(2))
                                                                                        -
                                                                                        (yearly
                                                                                            ?
                                                                                            Number(((cartItemsTotal * MONTHS * yearly_discount_percent) / 100).toFixed(2))
                                                                                            :
                                                                                            0)),
                                                                                    (discount_percent as number)))) : discount_fixed || minimum_amount || 0})</span>
                                                                            </div>
                                                                            <i className="fa-solid fa-xmark" onClick={() => {
                                                                                dispatch(cartSelectBasinCountyActions.handlePromoCodeDetails({ reset: true }))
                                                                            }}></i>
                                                                        </div>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>

                                    <div className="links">
                                        <ul>
                                            <li><a href="#">How does seat billing work?</a></li>
                                            <li><a href="#">How do I update my subscription?</a></li>
                                            <li><a href="#">What happens if I cancel a seat mid-cycle?</a></li>
                                            <li><a href="#">Privacy Policy</a></li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <h3>Current Subscriptions</h3>
                                    <div className="subsbox mb-4">
                                        <div className="headertop">
                                            Subscriptions <span>    {`${subscriptionData && subscriptionData[
                                                "subscription"
                                            ][
                                                "get_billing_frequency_display"
                                            ] ===
                                                "Monthly"
                                                ? "Monthly"
                                                : "Annual"
                                                }`} Cost</span>
                                        </div>
                                        <div className="insidebox">
                                            <div className="price-list scrollSection">
                                                <ul>
                                                    {subscriptionData &&
                                                        [
                                                            ...subscriptionData.details.filter(
                                                                (item) =>
                                                                    !item.unsubscribe_status
                                                            ),
                                                            ...subscriptionData.details.filter(
                                                                (item) =>
                                                                    item.unsubscribe_status
                                                            )
                                                        ].map((item, index) => {
                                                            return <li key={index}>
                                                                <div className="content">
                                                                    <div className="small-map">
                                                                        <img src={item.image_data} alt="" />
                                                                    </div>
                                                                    <div className="description">
                                                                        <h4>
                                                                            {
                                                                                item.line_item_name
                                                                            }{" "}
                                                                            {item.line_item_type ===
                                                                                2
                                                                                ? "County"
                                                                                : ""}
                                                                        </h4>
                                                                        <h6 className={
                                                                            item.line_item_type ===
                                                                                1
                                                                                ? ""
                                                                                : "d-none"
                                                                        }>{
                                                                                item.total_counties
                                                                            }{" "}
                                                                            Counties</h6>
                                                                        <p className="">
                                                                            {USDollar.format(
                                                                                Number(
                                                                                    item.total_cost
                                                                                )
                                                                            )}&nbsp;
                                                                            per seat/
                                                                            {`${subscriptionData[
                                                                                "subscription"
                                                                            ][
                                                                                "get_billing_frequency_display"
                                                                            ] ===
                                                                                "Monthly"
                                                                                ? "month"
                                                                                : "year"
                                                                                }`}
                                                                            -
                                                                            Billed{" "}
                                                                            {`${subscriptionData[
                                                                                "subscription"
                                                                            ][
                                                                                "get_billing_frequency_display"
                                                                            ] ===
                                                                                "Monthly"
                                                                                ? "monthly"
                                                                                : "yearly"
                                                                                }`}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="price">{USDollar.format(
                                                                    Number(
                                                                        item.total_cost
                                                                    ) * subscriptionData.subscription.allowed_user
                                                                )}
                                                                    <span>{subscriptionData.subscription.allowed_user} {subscriptionData.subscription.allowed_user === 1 ? "Seat" : "Seats"}</span>
                                                                </div>
                                                            </li>
                                                        })}
                                                </ul>
                                            </div>
                                            <div className="total-block">
                                                <ul className="subtotal">
                                                    <li>
                                                        <label>{`${subscriptionData && subscriptionData[
                                                            "subscription"
                                                        ][
                                                            "get_billing_frequency_display"
                                                        ] ===
                                                            "Monthly"
                                                            ? "Monthly"
                                                            : "Annual"
                                                            }`} total </label>
                                                        <span>
                                                            {USDollar.format(
                                                                cartTotalCost * allowedSubUser
                                                            )}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Order Summary</h3>
                                    <div className="box p-0">
                                        {(Number(seat_count) > allowedSubUser) ? <div className="total-block">
                                            <ul className="subtotal">
                                                <li>
                                                    <label>{Number(seat_count) - allowedSubUser} Additional {Number(seat_count) - allowedSubUser === 1 ? "Seat" : "Seats"}
                                                    </label>
                                                    <span>
                                                        {
                                                            USDollar.format(additionalSeatCost)
                                                        }
                                                    </span>
                                                </li>
                                                <li>
                                                    <label>Discount {Number(seat_count) > allowedSubUser ? `${seatDiscountPercentage(seat_count as string)}%` : ""}</label>
                                                    <span>
                                                        {
                                                            "-" + USDollar.format(additionalSeatCostDiscount)
                                                        }
                                                    </span>
                                                </li>
                                                <li>
                                                    <label>Pre-tax total </label>
                                                    <span>
                                                        {
                                                            USDollar.format((additionalSeatCost - additionalSeatCostDiscount))
                                                        }
                                                    </span>
                                                </li>
                                                <li>
                                                    <label>Taxes </label>
                                                    <span>
                                                        {
                                                            USDollar.format(additionalSeatCostTax)
                                                        }
                                                    </span>
                                                </li>
                                                <li>
                                                    <label><strong> Due Today</strong></label>
                                                    <span><strong>{
                                                        USDollar.format((additionalSeatCost - additionalSeatCostDiscount) + additionalSeatCostTax)
                                                    }</strong></span>
                                                </li>
                                            </ul>
                                        </div> : <></>}
                                        <div className="text-list">
                                            <ul>
                                                {Number(seat_count) > allowedSubUser ? <>
                                                    <li>Today, we'll charge you <strong>{USDollar.format((additionalSeatCost - additionalSeatCostDiscount))}</strong> for the cost of the <strong>{Number(seat_count) - allowedSubUser}</strong> additional seats + applicable taxes.</li>
                                                    <li>We will bill you <strong> {USDollar.format(nextBillingCost)}</strong> per {`${subscriptionData && subscriptionData[
                                                        "subscription"
                                                    ][
                                                        "get_billing_frequency_display"
                                                    ] ===
                                                        "Monthly"
                                                        ? "month"
                                                        : "year"
                                                        }`} + applicable taxes starting from your next billing cycle unless you make any further changes.</li>
                                                </>
                                                    :
                                                    <>
                                                        <li>You are currently subscribed to
                                                            {goNationalSubscribed.length ?
                                                                <>
                                                                    Nation wide at  {USDollar.format(
                                                                        Number(goNationalSubscribed[0]['total_cost'])
                                                                    )} per seat per {`${subscriptionData && subscriptionData[
                                                                        "subscription"
                                                                    ][
                                                                        "get_billing_frequency_display"
                                                                    ] ===
                                                                        "Monthly"
                                                                        ? "month"
                                                                        : "year"
                                                                        }`}
                                                                </>
                                                                :
                                                                <>
                                                                    &nbsp;<strong>{basinSubscribed.length}</strong>&nbsp;Tier&nbsp;
                                                                    {basinSubscribed.length ? <>{seat_count} Basins at <strong>
                                                                        {USDollar.format(
                                                                            basinTotalCost
                                                                        )
                                                                        }</strong> per seat per {`${subscriptionData && subscriptionData[
                                                                            "subscription"
                                                                        ][
                                                                            "get_billing_frequency_display"
                                                                        ] ===
                                                                            "Monthly"
                                                                            ? "month"
                                                                            : "year"
                                                                            }`}</> : ""}
                                                                    {countySubscribed.length ? <>, and&nbsp;{countySubscribed.length} County at <strong>
                                                                        {USDollar.format(
                                                                            countyTotalCost
                                                                        )
                                                                        }</strong> per seat per {`${subscriptionData && subscriptionData[
                                                                            "subscription"
                                                                        ][
                                                                            "get_billing_frequency_display"
                                                                        ] ===
                                                                            "Monthly"
                                                                            ? "month"
                                                                            : "year"
                                                                            }`}</> : ""}
                                                                </>
                                                            } before applicable discount are applied.
                                                        </li>
                                                        <li>Your total {`${subscriptionData && subscriptionData[
                                                            "subscription"
                                                        ][
                                                            "get_billing_frequency_display"
                                                        ] ===
                                                            "Monthly"
                                                            ? "Monthly"
                                                            : "Annual"
                                                            }`} payment is <strong> {USDollar.format(cartTotalCost * allowedSubUser)}</strong>  (before seat discounts are applied) + applicable taxes.
                                                        </li>
                                                        <li>No Additional charges at this time.</li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                        <div className="btn-action">
                                            {Number(seat_count) && Number(seat_count) > allowedSubUser && Number(seat_count) <= 10 ?
                                                <button type={"submit"} className="btn btn-primary width100">Pay  {USDollar.format((additionalSeatCost - additionalSeatCostDiscount) + additionalSeatCostTax)} now</button>

                                                :
                                                <button type={seat_count && Number(seat_count) < allowedSubUser ? "submit" : "button"} className={`btn btn-primary width100 ${seat_count && Number(seat_count) < allowedSubUser ? "" : "initial"}`}>Pay $0.00 now</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </form>
                </Modal >
            }
        </>

    );
}

export default PaymentModal;
